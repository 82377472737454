@import 'colors';
@import 'variables';
@import 'mixins';

@font-face {
  font-family: 'Inter-Black';
  src: url('../fonts/Inter-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('../fonts/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../fonts/Inter-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../fonts/Inter-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-Thin';
  src: url('../fonts/Inter-Thin.ttf') format('truetype');
}

body {
  background-color: $background-color;
  height: 100%;
  margin: 0;
}

.login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: $gray-color-4;
  height: 100vh;
  flex-direction: column;

  form {
    padding-top: 20px;
    position: relative;
    background-color: $black-color-2;
    width: 533px;
    box-shadow: 8px 16px 15px 3px #00000075;
    border-radius: 22px 22px 22px 22px;

    h1 {
      color: white;
      font-size: 22px;
      padding-top: 25px;
      margin-bottom: 15px;
    }

    input {
      background: none;
      width: 100%;
      border: 1px solid $gray-color-3;
      border-radius: 7px;
      height: 38px;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 18px;
      font-size: 12px;
      color: white;

      &:focus {
        color: white;
        background: none;
        outline: none;
      }
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
      font-size: 12px;
    }

    .input-error {
      color: $gray-color-3;
      font-size: 10px;
      text-align: left;
      vertical-align: middle;
    }

    .login-button {
      font-family: 'Inter-Bold';
      background-color: $green-color;
      border: none;
      border-radius: 0px 0px 17px 17px;
      width: 100%;
      position: relative;
      bottom: 0;
      left: 0;
      height: 50px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      margin-top: 15px;
    }

    .recover-password {
      color: white;
      font-size: 12px;
      text-align: right;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &.my-form {
    background: url('../images/sierra_home.jpg') no-repeat;
    background-size: cover;
  }

  .error-message {
    font-size: 14px !important;
  }
}

.billing-summary {
  background-color: $black-color;
  padding: 12px 12px 15px 12px;
  border-radius: 11px;
  box-shadow: 4px 4px 7px #00000029;
  min-height: 103px;

  h4 {
    font-size: 14px;
    color: $gray-color-2;
  }

  .amount {
    font-size: 24px;
    font-weight: 600;
    color: $white-color;
  }

  .percentage {
    font-size: 10px;
    color: $white-color;
  }
}

.dashboard-sales-stats {
  .billing-summary {
    min-height: 118px;
  }
}

.dashboard {
  ul.dashboard-tabs {
    display: flex;
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;

      &:not(:last-child) {
        margin-right: 10px;
      }

      a {
        border-radius: 7px;
        padding: 8px 120px 8px 8px;
        background-color: $gray-color-2;
        color: $white-color;
        text-decoration: none;
        box-shadow: 4px 4px 7px #00000029;

        &:hover,
        &.active {
          background-color: $green-color;
        }

        svg {
          margin-right: 10px;
          margin-bottom: 3px;
        }
      }
    }
  }

  .dashboard-tab {
    display: none;
    padding: 20px 11px 40px 11px;
    background-color: $white-color;
    border-radius: 11px;
    box-shadow: 4px 4px 7px #00000029;
    /* max-height: 80vh; */

    h4 {
      color: $gray-color-2;
      font-size: 14px;
      margin-bottom: 20px;
    }

    &.active {
      display: block;
    }

    /* @media (max-width: 1024px) {
      .table-container {
        height: 506px;
      }
    } */

    table {
      th,
      td {
        padding: 10px;
        font-weight: 600;

        &:not(:first-child) {
          text-align: center;
        }
      }

      thead {
        background-color: $black-color;

        tr {
          color: $white-color;
        }
      }
    }
  }

  .best-sellers {
    background-color: $white-color;
    border-radius: 11px;
    padding: 20px 10px 30px 10px;
    box-shadow: 4px 4px 7px #00000029;

    h4 {
      color: $gray-color-2;
      font-size: 14px;
      margin-bottom: 20px;
    }

    table {
      th,
      td {
        padding: 5px 10px;
        font-weight: 400;

        &:not(:first-child) {
          text-align: center;
        }
      }

      thead {
        background-color: $black-color;

        tr {
          color: $white-color;
        }
      }

      tbody {
        tr {
          td:first-child {
            max-width: 150px !important;
            overflow-x: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }
      }
    }
  }

  .dropdown {
    flex-grow: 1;
  }

  label {
    font-size: 12px;
    color: #b9b9b9;
    margin-bottom: 5px;
  }
  select {
    font-size: 14px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 38px;
    padding-left: 10px;
    color: #808a95;

    &:focus {
      background: none;
      outline: none;
    }
  }
}

.dashboard,
.sales,
.inventories,
.delivery-notes-container,
.returns,
.repairs,
.expirations,
.cash-register,
.invoices,
.withdrawals {
  .dropdown {
    box-shadow: 4px 4px 7px #00000029;
    border-radius: 11px;

    .container-selected-date-range {
      width: 100%;
      min-width: 160px;
    }

    .dropdown-menu {
      width: 100%;
      border-radius: 0 0 8px 8px;
      margin-top: -5px;
      border: none;
      box-shadow: 4px 4px 7px #00000029;

      .react-datepicker {
        border: none;
        border-radius: 8px;
        width: 96%;
        margin: 0 auto;
        display: block;

        .react-datepicker__month-container {
          width: 100%;
          background-color: $background-color;

          .react-datepicker__header {
            border: none;
            background-color: $background-color;

            .react-datepicker__current-month {
              color: $gray-color-2;
              font-size: 12px;
              text-transform: capitalize;
            }

            .react-datepicker__day-names {
              display: flex;
              justify-content: space-between;

              .react-datepicker__day-name {
                flex-grow: 1;
                color: $black-color;
                font-size: 10px;
                text-transform: uppercase;
              }
            }
          }

          .react-datepicker__week {
            display: flex;
            justify-content: space-between;

            .react-datepicker__day {
              flex-grow: 1;
              margin: 0;
              border-radius: 0;

              &:hover {
                border-radius: 10px;
                background-color: #0092fd33;
                color: #000;
              }

              &.react-datepicker__day--selected {
                border-radius: 10px;
              }

              &.react-datepicker__day--range-start {
                border-radius: 10px 0 0 10px;
              }

              &.react-datepicker__day--range-end {
                border-radius: 0 10px 10px 0;
              }

              &.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
                border-radius: 10px 0 0 10px;
              }

              &.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
                border-radius: 10px;
              }
            }

            .react-datepicker__day--in-selecting-range {
              background-color: #0092fd33;
              color: #000;

              &:last-child {
                border-radius: 0 10px 10px 0;
              }
            }

            .react-datepicker__day--in-range {
              background-color: #0092fd33;
              color: #000;
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--range-end {
              background-color: $blue-color-2;
              color: $white-color;
            }
          }
        }
      }
    }
  }
}

.my-container {
  position: absolute;
  left: $header-width;
  width: calc(100% - #{$header-width});
  height: 100%;

  h1 {
    font-family: 'Inter-SemiBold';
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.my-table {
  width: 100%;
  overflow-wrap: break-word;

  input,
  select {
    background: transparent;
    border: 1px solid $gray-color-2;
    border-radius: 7px;
    height: 30px;
    padding-left: 10px;
    color: $gray-color-2;
    width: 95%;

    &:focus {
      background: none;
      outline: none;
    }
  }
}

.my-pagination {
  margin-top: 20px;
  color: $gray-color-2;
  font-size: 12px;
  font-family: 'Inter-Light';

  .page {
    cursor: default;
    margin-left: 5px;
    margin-right: 5px;
  }

  .page.active {
    font-family: 'Inter-Bold';
  }

  .selectable {
    cursor: pointer;
  }

  .previous-page {
    margin-right: 20px;
  }

  .next-page {
    margin-left: 20px;
  }

  .disabled {
    cursor: default;
  }
}

.badge {
  font-family: 'Inter-SemiBold';
  color: white;
  border-radius: 8px;
  vertical-align: middle;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.badge.status-mark {
  width: 16px;
  height: 16px;
  display: block;
}

.my-success {
  background-color: $green-color;
}

.my-danger {
  background-color: $red-color;
}

.my-info {
  background-color: $blue-color;
}

.my-warning {
  background-color: $orange-color;
}

.my-gray {
  background-color: $gray-color-2;
}

.my-pink {
  background-color: $pink-color;
}

.delivery-note-cancelled {
  background-color: $gray-color-2;
}

.delivery-note-pending {
  background-color: $orange-color-2;
}

.delivery-note-received {
  background-color: $blue-color-2;
}

.delivery-note-invoiced {
  background-color: $green-color;
}

.cash-register-closed {
  background-color: $green-color;
}

.cash-register-resolved {
  background-color: $orange-color-3;
}

.cash-register-incidence {
  background-color: $red-color;
}

.inventory-closed {
  background-color: $green-color;
}

.inventory-draft {
  background-color: $blue-color;
}

.inventory-resolved {
  background-color: $orange-color-3;
}

.inventory-incidence {
  background-color: $red-color;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.error {
  color: $red-color !important;
}

.ReactModal__Overlay--after-open {
  z-index: 9999;
}

.vercomi-modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $black-color;
  border-radius: 28px;
  box-shadow: 6px 6px 9px #00000029;
  outline: none;
}

.my-form {
  color: white;
  position: relative;
  padding-top: 20px;

  .home-logo {
    max-width: 600px;
    margin-bottom: 60px;
  }

  .content {
    padding-left: 20px;
    padding-right: 20px;
    max-height: 80vh;
    overflow-y: auto;

    .title {
      font-size: 22px;
      font-family: 'Inter-SemiBold';
    }

    table {
      thead {
        background-color: transparent;
      }

      tbody {
        td {
          color: $white-color;
        }
      }
    }
  }

  .input-name {
    font-size: 11px;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='email'],
  input[type='date'],
  input[type='datetime-local'],
  select,
  textarea {
    width: 100%;
    background: transparent;
    height: 40px;
    border: 1px solid white;
    border-radius: 10px;
    padding-left: 20px;
    color: white;
    font-size: 14px;
    font-family: 'Inter-SemiBold';
  }

  textarea {
    padding-top: 10px;
    width: 100%;
    height: 90px;
  }

  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    color: gray;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none !important;
    border: 1px solid $green-color;
    color: white;
  }

  input.error,
  select.error,
  textarea.error {
    border: 1px solid $red-color !important;
  }

  input[type='checkbox'] {
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }

  .error-message {
    margin-top: 5px;
    margin-left: 5px;
    color: $red-color;
    font-size: 11px;
  }

  .contact-info {
    text-transform: uppercase;
    font-family: 'Inter-SemiBold';
    color: $gray-color-2;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  select {
    background-color: #252e3c;
  }
}

.pin-modal {
  position: relative;
  text-align: center;
  width: 434px;

  @media (max-width: 1024px) {
    width: 530px;
  }

  h1 {
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 25px;
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 23px;
    background: none;
    color: white;
    border: none;
  }

  .container-buttons {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 80px;
    margin-right: 80px;

    @media (max-width: 1025px) {
      position: relative !important;
      width: auto !important;
      bottom: 0 !important;
    }

    .row {
      margin-top: 17.5px;
      margin-bottom: 17.5px;

      button {
        background-color: transparent;
        background-repeat: no-repeat;
        border: 1px solid white;
        aspect-ratio: 1;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        color: white;
        height: 63px;
        font-size: 20px;
      }
    }
  }

  .container-dots {
    height: 20px;
    text-align: center;

    .dot {
      height: 15px;
      width: 15px;
      margin-left: 7.5px;
      margin-right: 7.5px;
      background-color: white;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .container-password {
    margin-top: 30px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    .input-name {
      text-transform: uppercase;
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      color: #808a95;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      background: transparent;
      height: 40px;
      border: 1px solid white;
      border-radius: 10px;
      padding-left: 20px;
      color: white;
      font-size: 14px;
      font-family: 'Inter-SemiBold';
    }
  }

  .select-user {
    color: white;
    background-color: $black-color;
    font-size: 14px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid white;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    padding-left: 15px;
    width: auto;
  }
}

.save-button {
  font-family: 'Inter-Bold';
  background-color: $green-color;
  border: none;
  border-radius: 0px 0px 17px 17px;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  height: 50px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 30px;
}

.delete-button {
  font-family: 'Inter-Bold';
  background-color: $red-color;
  border: none;
  border-radius: 0px 0px 0px 17px;
  width: 50%;
  position: relative;
  bottom: 0;
  left: 0;
  height: 50px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 30px;
}

.modify-button {
  font-family: 'Inter-Bold';
  background-color: $green-color;
  border: none;
  border-radius: 0px 0px 17px 0px;
  width: 50%;
  position: relative;
  bottom: 0;
  left: 0;
  height: 50px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 30px;
}

.save-button.disabled,
.save-button:disabled {
  background-color: $gray-color-2;
  cursor: not-allowed;
}

.create-button {
  background-color: $green-color;
  color: white;
  border: none;
  border-radius: 10px;
  height: 30px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  font-family: 'Inter-SemiBold';
  line-height: 14px;

  &:disabled {
    background-color: $gray-color-2;
    cursor: not-allowed;
  }
  &:hover {
    color: white;
  }
}

.export-csv {
  color: $gray-color-2;
  height: 30px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  font-family: 'Inter-SemiBold';
  text-decoration: none;

  &:hover {
    color: $green-color;
  }
  &.disabled {
    cursor: not-allowed !important;
  }
  &.disabled:hover {
    color: $gray-color-2;
  }
}

.create-invoice {
  background-color: $gray-color-2;
  color: white;
  border: none;
  border-radius: 10px;
  height: 30px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  font-family: 'Inter-SemiBold';
}

.generate-invoice {
  background-color: $blue-color-2;
  color: white;
  border: none;
  border-radius: 10px;
  height: 30px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  font-family: 'Inter-SemiBold';
}

.generate-invoice:disabled {
  background-color: $gray-color-2;
}

.icon-button {
  color: white;
  border: none;
  border-radius: 4px;
  height: 20px;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
  font-family: 'Inter-SemiBold';
}

.close-button-modal {
  background: none;
  color: white;
  border: none;
}

.customer-modal {
  width: 824px;

  form {
    .content {
      max-height: 80vh;
      overflow-y: auto;
    }
  }

  .customer-button {
    background-color: $blue-color-2;
    color: white;
    border: none;
    border-radius: 7px;
    height: 30px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    &:disabled {
      background-color: $gray-color-2;
      cursor: not-allowed;
    }
  }
  .danger {
    background-color: $red-color;
  }

  .button {
    font-family: 'Inter-Bold';
    border: none;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .save-button {
    background-color: $green-color;
    border-radius: 0px 0px 17px 17px;
    border-right: 1px solid white;
  }

  .firma-cliente {
    border: 1px solid $gray-color-3;
    border-radius: 7px;
  }
}

.category-modal {
  width: 500px;
}

.subCategory-modal {
  width: 500px;
}

.brand-modal {
  width: 500px;
}

.create-stock-modal {
  width: 750px;
  input:disabled,
  select:disabled {
    cursor: not-allowed;
  }
}

.user-work-clockings-history-modal {
  .main {
    height: auto;
  }
  width: 95%;
  color: white;
  position: relative;
  padding: 20px;
  .content {
    padding-left: 20px;
    padding-right: 20px;
    max-height: 80vh;
    overflow-y: auto;
    .title {
      font-size: 22px;
      font-family: 'Inter-SemiBold';
    }
  }
  table {
    tbody {
      tr {
        td {
          color: $white-color;
        }
      }
    }
  }
  .my-pagination {
    color: white;
  }
}

.work-shifts-modal {
  width: 600px;
  .input-name {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: #808a95;
    text-transform: uppercase;
  }
  input[type='time'] {
    width: 100%;
    height: 30px;
    border: 1px solid #d3d3d3;
    height: 38px;
    border-radius: 7px;
    background-color: transparent;
    padding-left: 10px;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  .container-checkbox {
    input[type='checkbox'] {
      width: 14px;
      height: 14px;
      appearance: none;
      border: 1px solid #d3d3d3;
      border-radius: 2px;
      background-color: transparent;
    }
    input[type='checkbox']:checked {
      padding-top: 2px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath stroke='white' d='M1,1l6,6m0,-6L1,7'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70%;
    }
    label {
      font-family: 'Inter-Regular';
      font-size: 10px;
      color: #d3d3d3;
      text-transform: uppercase;
    }
  }
  .rmdp-container {
    width: 100%;
  }
}

.sign-modal {
  width: 650px;

  .button {
    font-family: 'Inter-Bold';
    border: none;
    width: 50%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .clear-button {
    background-color: $red-color;
    border-radius: 0px 0px 0px 17px;
  }

  .save-button {
    background-color: $green-color;
    border-radius: 0px 0px 17px 0px;
  }

  .sign-title {
    font-size: 12px;
    text-transform: uppercase;
    color: $gray-color-2;
    font-weight: 600;
  }
}

.collection-modal {
  width: 500px;
}

.family-modal {
  width: 500px;
}

.subFamily-modal {
  width: 500px;
}

.replace-manual-sale-product-modal {
  width: 650px;
  max-height: 800px;
  .table-products {
    color: black;
    background-color: white;
    padding-bottom: 20px;
    .table-container {
      .my-table {
        text-align: center;
        font-size: 12px;
        thead {
          tr {
            th {
              padding-left: 5px;
              line-height: 3;
              background: rgb(248, 249, 250);
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid $gray-color-3;
            height: 50px;
            td {
              color: #252e3c;
              padding-left: 5px;
              input[type='radio'] {
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
  .save-button:disabled {
    background-color: white;
    background-color: $gray-color-2;
    cursor: not-allowed;
  }
}

.cancel-reservation-modal {
  width: 600px;
}

.update-online-stock-modal {
  width: 600px;

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 50px;
    height: 25px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #808a9559;
    transition: background-color ease 0.3s;

    @media (max-width: 1024px) {
      width: 23px;
      height: 12px;
    }

    &:before {
      content: 'on off';
      display: block;
      position: absolute;
      z-index: 2;
      width: 21px;
      height: 21px;
      background: $white-color;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      font: 9px/23px Helvetica;
      text-transform: uppercase;
      font-weight: bold;
      text-indent: -21px;
      word-spacing: 30px;
      color: $white-color;
      text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
      white-space: nowrap;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;

      @media (max-width: 1024px) {
        width: 10px;
        height: 10px;
        left: 1px;
        top: 1px;
        text-indent: -130px;
      }
    }

    &:checked {
      background-color: $green-color;

      &:before {
        left: 27px;

        @media (max-width: 1024px) {
          left: 12px;
        }
      }
    }
  }

  .save-button {
    margin-top: 10px;
  }
}

.import-csv-modal {
  width: 600px;
  p {
    color: #a0aec1;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
  }
  .download-example-csv {
    text-decoration: underline;
    cursor: pointer;
  }
  .container-import-csv-file {
    display: inline-block !important;
    border-style: solid;
    border-color: $gray-color-3;
    border-width: 1px;
    border-radius: 7px;
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 20px;
    margin-bottom: 1rem;
    cursor: pointer;
    input {
      display: none;
    }
  }
  .modal-button {
    font-family: 'Inter-Bold';
    background-color: $green-color;
    border: none;
    border-radius: 0px 0px 17px 17px;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .modal-button:disabled {
    background-color: $gray-color-2;
    cursor: not-allowed;
  }
  .back-button {
    background-color: $gray-color-2;
    width: 50%;
    border-radius: 0px 0px 0px 17px;
  }
  .back-button-red {
    background-color: $red-color;
    width: 50%;
    border-radius: 0px 0px 0px 17px;
  }
  .next-button {
    width: 50%;
    border-radius: 0px 0px 17px 0px;
  }
  .container-selects {
    height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: white; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
    }
    .container-select-column {
      margin-bottom: 10px;
      .container-key {
        width: 35%;
        label {
          font-family: 'Inter-SemiBold';
          font-size: 14px;
          color: white;
          text-align: end;
          padding-right: 5%;
          .mandatory {
            margin-left: 5px;
            color: red;
          }
          .required {
            margin-left: 5px;
            color: white;
          }
        }
        span {
          font-family: 'Inter-SemiBold';
          font-size: 10px;
          color: white;
          text-align: end;
          padding-right: 5%;
        }
      }
      select {
        width: 50%;
        font-family: 'Inter-SemiBold';
        font-size: 14px;
        color: white;
        border: 1px solid white;
        border-radius: 7px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  .container-mappings {
    background-color: #ffffff;
    border-radius: 13px;
    padding: 20px 20px;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: white; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
    }
    .container-key-value {
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      .key {
        color: #252e3c;
      }
      .value {
        color: $gray-color-2;
      }
    }
  }
  .container-confirm {
    text-align: center;
    font-family: 'Inter-SemiBold';
    margin-top: 50px;
    margin-bottom: 50px;
    .title {
      font-size: 16px;
      color: #a0aec1;
    }
    .description {
      font-size: 14px;
      color: white;
    }
  }
  .container-import-result {
    max-height: 500px;
    margin-bottom: 10px;
    .container-results {
      margin-top: 20px;
      margin-bottom: 20px;
      label {
        font-family: 'Inter-SemiBold';
        font-size: 18px;
        color: $gray-color-2;
      }
      span {
        margin-left: 10px;
        font-family: 'Inter-SemiBold';
        font-size: 18px;
        color: $gray-color-3;
      }
    }
    .container-table {
      max-height: 350px;
      background-color: white;
      border-radius: 10px;
      padding: 15px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px; /* width of the entire scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background-color: #252e3c; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
      }
      table {
        width: 100%;
        thead {
          color: $gray-color-2;
          font-family: 'Inter-SemiBold';
          font-size: 12px;
          tr {
            line-height: 3;
            th:first-child {
              width: 15%;
            }
            th:nth-child(2) {
              width: 85%;
            }
          }
        }
        tbody {
          tr {
            td {
              font-family: 'Inter-Regular';
              font-size: 12px;
              color: #252e3c;
              border-top: 0.1px solid $gray-color-2;
              line-height: 3;
            }
          }
        }
      }
    }
  }
}

.expiration-modal {
  width: 800px;

  h6 {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .table-payments {
    color: $black-color;
    background-color: white;
    .my-table {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      thead {
        tr {
          th {
            padding-left: 5px;
            line-height: 3;
            background: rgb(248, 249, 250);
          }
        }
      }
      tbody {
        tr {
          td {
            line-height: 3;
            padding-left: 5px;
            color: $black-color;
          }
        }
      }
    }
  }

  .button-expiration-payment {
    color: white;
    border: none;
    border-radius: 6px;
    height: 30px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    margin-left: 5px;
    margin-right: 5px;
  }
  .button-expiration-payment.disabled {
    background-color: $gray-color-2;
  }
  .add-expiration-payment {
    background-color: $blue-color;
  }
  .cancel-expiration-payment {
    background-color: $gray-color-2;
  }
}

.reassing-sale-modal {
  width: 1200px;
  tr {
    border-bottom: 1px solid $gray-color-3;
    line-height: 2.5;
  }
  .button-create-customer {
    background-color: $blue-color;
    color: white;
    border: none;
    border-radius: 7px;
    height: 30px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    margin-left: 5px;
    margin-right: 20px;
  }
}

.import-delivery-note-modal {
  width: 800px;
}

.import-electronic-invoice-modal {
  width: 550px;
}

.import-stock-modal {
  width: 800px;
}

.update-products-modal {
  width: 600px;
}

.withdrawal-modal {
  width: 500px;
}

.upload-product-image-modal {
  width: 700px;
}

.open-file {
  font-family: 'Inter-SemiBold';
  font-size: 12px;
  border: 1px solid $blue-color;
  background-color: $blue-color;
  color: white;
  border-radius: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.logout-modal {
  width: 500px;

  .my-button {
    font-family: 'Inter-Bold';
    border: none;
    width: 50%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .cancel-button {
    background-color: $gray-color-2;
    border-radius: 0px 0px 0px 17px;
  }

  .save-button {
    background-color: $red-color;
    border-radius: 0px 0px 17px 0px;
  }

  .my-button.disabled {
    background-color: $gray-color-2;
  }
}

.threshold-modal {
  width: 636px;
  .subtitle {
    color: #a0aec1;
    font-family: 'Inter-SemiBold';
    font-size: 18px;
  }
  .input-name {
    text-transform: uppercase;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: #808a95;
  }
  .step-2 {
    margin-bottom: 30px;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .selection {
    font-family: 'Inter-SemiBold';
    color: #d3d3d3;
    font-size: 16px;
    text-align: center;
    border: 1px solid #d3d3d3;
    padding: 5px 20px;
    border-radius: 8px;
    min-width: 180px;
    cursor: pointer;
  }
  .selection.selected {
    color: white;
    border: transparent;
    background-color: #26c44b;
  }
  .selection.error {
    color: $red-color;
    border: 1px solid $red-color;
  }

  .previous-step {
    font-family: 'Inter-Bold';
    background-color: #808a95;
    border: none;
    border-radius: 0px 0px 0px 17px;
    width: 50%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
  }
  .next-step {
    font-family: 'Inter-Bold';
    background-color: $green-color;
    border: none;
    border-radius: 0px 0px 17px 0px;
    width: 50%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
  }
  .info-no-data {
    font-size: 12px;
    padding-left: 5px;
  }
}

.online-store-modal {
  width: 824px;

  .use-stock-rest-stores {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
  }
  .no-organizations {
    font-size: 12px;
    margin-left: 5px;
  }
}

.confirm-modal {
  width: 600px;

  .title {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    text-transform: uppercase;
  }

  button {
    font-family: 'Inter-Bold';
    border: none;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    height: 50px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
    &:disabled {
      cursor: not-allowed;
      background-color: $gray-color-2;
      color: white;
    }
  }

  .accept-button {
    border-radius: 0px 0px 17px 0px;
    background-color: $green-color;
  }

  .delete-button {
    border-radius: 0px 0px 17px 0px;
    background-color: $red-color;
  }

  .cancel-button {
    border-radius: 0px 0px 0px 17px;
    background-color: $gray-color-2;
  }
}

.products-finder-modal {
  width: 500px;

  input {
    height: 30px;
  }
}

.custom-product-option {
  cursor: pointer;
  border-bottom: 1px solid white;
  padding-left: 10px;
  color: white;
  .name {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
  }
  .sku {
    font-family: 'Inter-SemiBold';
    font-size: 10px;
  }
}

.notes-modal {
  width: 650px;

  textarea {
    font-family: 'Inter-Regular';
    width: 100%;
    height: 300px;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 12px;
    color: $white-color;

    &:focus {
      border: 1px solid $gray-color;
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .remove-notes {
    color: $gray-color-2;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    cursor: pointer;
  }
}

.user-modal {
  width: 824px;
  .form-section {
    font-family: 'Inter-Semibold';
    font-size: 12px;
    color: #808a95;
    text-transform: uppercase;
  }
  .my-color-picker {
    width: 40px;
    height: 40px;
    border-radius: 7px 7px 7px 7px;
    cursor: pointer;
  }
  .container-add-photo {
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 4px;
    cursor: pointer;
  }
  .user-photo {
    border: 1px solid white;
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .remove-icon {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    font-family: 'Inter-Regular';
    font-size: 12px;
    height: 20px;
    width: 20px;
    background-color: $red-color;
    color: white;
    border-radius: 50%;
  }

  .remove-icon-small {
    height: 14px;
    width: 14px;
  }
}

.organization-modal {
  width: 824px;
}

.supplier-modal {
  width: 824px;
}

.technical-service-modal {
  width: 824px;
}

.store-modal {
  width: 824px;

  .container-image {
    .product-image {
      object-fit: contain;
    }
  }

  .title-tpv {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.pos-discount-modal {
  width: 500px;

  .remove-discount {
    color: $gray-color-2;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
    cursor: pointer;
  }
}

.change-user-password-modal {
  width: 500px;
}

.cash-register-modal {
  width: 1000px;

  .content {
    padding-left: 40px;
    padding-right: 40px;

    .container-money {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 19%;
      position: relative;
      border: 2px solid $gray-color-2;
      border-radius: 7px;
      margin-left: 4px;
      margin-right: 4px;

      .money-type {
        position: absolute;
        top: 3px;
        right: 3px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $orange-color-3;
      }

      .header-money {
        font-family: 'Inter-Regular';
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        height: 65px;
        color: $gray-color-5;

        @media (max-width: 1025px) {
          height: 50px;

          img {
            height: 30px;
          }
        }
      }

      .header-tpv {
        font-family: 'Inter-bold';
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .icon-tpv {
        height: 16px;
        width: 23px;
        border-radius: 3px;
        background-color: $blue-color-2;
      }

      .separator {
        height: 2px;
        background-color: $gray-color-2;
      }

      .body-money {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 10px;
        padding-right: 10px;

        input {
          font-size: 14px;
          width: 60px;
          height: 30px;
          border-radius: 6px;
          color: $gray-color-5;
          padding-left: 10px;
        }

        .input-cash:focus {
          outline: none !important;
          border: 1px solid $orange-color-3;
        }

        .input-cash-value {
          outline: none !important;
          border: 1px solid $orange-color-3;
        }

        .input-tpv {
          width: 100%;
        }

        .input-tpv:focus {
          outline: none !important;
          border: 1px solid $blue-color-2;
        }

        .input-tpv-value {
          outline: none !important;
          border: 1px solid $blue-color-2;
        }

        .input-withdrawals {
          width: 100%;
        }

        .input-withdrawals:focus {
          outline: none !important;
          border: 1px solid $green-color;
        }

        .input-withdrawals-value {
          outline: none !important;
          border: 1px solid $green-color;
        }

        .total-money {
          font-family: 'Inter-Regular';
          font-size: 14px;
        }
      }
    }

    .with-value-cash {
      border: 2px solid $orange-color-3;
    }

    .with-value-tpv {
      border: 2px solid $blue-color-2;
    }
    .with-value-withdrawals {
      border: 2px solid $green-color;
    }
    .totals {
      font-family: 'Inter-SemiBold';
      font-size: 16px;

      .money-type {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: $orange-color-3;
      }

      .icon-tpv {
        height: 16px;
        width: 23px;
        border-radius: 3px;
        background-color: $blue-color-2;
      }
    }

    textarea {
      width: 100%;
      border: 1px solid $gray-color-2;
      border-radius: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      font-family: 'Inter-Regular';
      color: $gray-color-2;
    }

    textarea:focus {
      outline: none;
      border: 1px solid $gray-color-2;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $black-color;
  border: 1px solid white;
}

input:focus + .slider {
  box-shadow: 0 0 1px $black-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px) translateY(1.25px);
  -ms-transform: translateX(20px) translateY(1.25px);
  transform: translateX(20px) translateY(1.25px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-text {
  font-size: 14px;
  font-family: 'Inter-SemiBold';
}

.bg-white {
  background-color: white;
}

.bg-default {
  background-color: $black-color-2;
}

.bg-info {
  background-color: $blue-color;
}

.my-border {
  border: 1px solid white;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.product-finder {
  background-color: white;

  h1 {
    font-size: 22px;
  }

  input,
  select {
    width: 100%;
    height: 38px;
    border: 1px solid $gray-color;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-family: 'Inter-Regular';
    color: $gray-color;
    background-color: transparent;

    &:focus {
      border: 1px solid $gray-color;
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $gray-color-3;
      color: white;
    }
  }

  input::placeholder {
    color: $gray-color;
    font-size: 15px;
    font-weight: 'bold';
  }

  input:disabled::-webkit-input-placeholder {
    /* WebKit browsers */
    color: white;
  }

  input:disabled:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: white;
  }

  input:disabled::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: white;
  }

  input:disabled:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: white;
  }

  .product-card {
    cursor: pointer;
    .img-thumbnail {
      margin: 0 auto;
      height: 100px;
      width: 100px;
      max-width: 100%;
      object-fit: cover;
    }

    img {
      border: 1px solid $gray-color-7;
    }

    .name {
      font-size: 12px;
      font-family: 'Inter-SemiBold';
    }

    .sku {
      font-size: 12px;
      font-family: 'Inter-Regular';
      color: $gray-color;

      @media (max-width: 1025px) {
        font-size: 10px;
      }
    }

    .price {
      font-size: 14px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;
    }

    .add-product {
      width: 100%;
      background-color: $green-color;
      color: white;
      outline: none;
      border: none;
      border-radius: 5px;
      font-size: 10px;
      text-transform: uppercase;
      padding: 2.5px;
      font-family: 'Inter-SemiBold';
      cursor: pointer;
    }

    .product-card-info {
      min-height: 60px;
    }
  }

  .product-finder-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 65vh;
  }

  .product-finder-list-short {
    overflow-y: auto;
    height: 37vh;
  }

  .infinite-scroll-component {
    overflow-x: hidden !important;
  }
}

.pos-details {
  h1 {
    font-size: 22px;
  }

  .container-buttons {
    font-family: 'Inter-Bold';

    .reservation-button {
      width: 35%;
      background-color: $black-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      border-right: 1px solid white;
    }

    .pay-button {
      width: 65%;
      background-color: $green-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
    }

    button.disabled {
      background-color: $gray-color-2;
    }

    .total-info {
      width: 35%;
      background-color: $black-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      cursor: default;
    }
  }

  .pos-products-table {
    .add-manual {
      display: block;
      border-radius: 5px;
      background-color: $gray-color-2;
      padding: 4px;
      cursor: pointer;

      svg {
        stroke: $white-color;
      }
    }

    .remove-icon {
      position: absolute;
      left: 7px;
    }
  }
}

.pos-products-table {
  .remove-icon {
    position: absolute;
    left: 7px;
  }
}

.sale-details {
  h1 {
    font-size: 22px;
  }
  .container-buttons {
    font-family: 'Inter-Bold';

    .return-button {
      width: 65%;
      background-color: $red-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
    }

    .change-button {
      width: 65%;
      background-color: $green-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
    }

    button.disabled {
      background-color: $gray-color-2;
    }

    .total-info {
      width: 35%;
      background-color: $black-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      cursor: default;
    }
  }

  .pos-products-table {
    thead {
      background-color: #f1f1f1 !important;
    }

    .manual-product {
      input::placeholder {
        font-size: 12px;
      }
    }
  }
}

.button-header {
  font-family: 'Inter-SemiBold';
  color: $gray-color-2;
  font-size: 12px;
  cursor: pointer;
}

.has-notes {
  font-family: 'Inter-SemiBold';
  background-color: $red-color;
  border-radius: 7px;
  color: white;
  padding: 3px 7px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.customer-option {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 14px;
  font-family: 'Inter-SemiBold';
  cursor: pointer;
  color: white;
}

.pos-customer {
  background-color: $gray-color-6;
  color: $black-color-2;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 22.5px;
  padding-right: 22.5px;

  .change-client {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    cursor: pointer;
    background-color: $blue-color;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 3px 6px;
  }

  .name {
    font-family: 'Inter-Bold';
    font-size: 14px;
    color: $black-color-2;
  }

  .details {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $black-color-2;
  }

  .positive-balance {
    color: $green-color;
  }

  .negative-balance {
    color: $red-color;
  }
  .no-balance {
    color: $gray-color-2;
  }

  .marketing {
    color: $green-color;
  }

  .no-marketing {
    color: $red-color;
  }

  .personal-data {
    color: $blue-color-2;
    text-decoration: none;
  }
}

.clickable {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.enter-text {
  font-family: 'Inter-Regular';
  color: white;
  font-size: 12px;
}

.sale-summary {
  overflow: auto;

  .date {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
  }

  @media (max-width: 1025px) {
    .table-container {
      table {
        width: 100%;
      }
    }
  }

  h1 {
    font-size: 22px;
    text-align: center;
  }

  .pos-products-table {
    thead {
      background-color: $white-color !important;
    }
  }
}

.make-payment {
  h1 {
    font-size: 22px;
  }

  .subtitle {
    font-family: 'Inter-Regular';
    font-size: 12px;
    color: $gray-color;
  }

  .payment-columns {
    width: 70%;
  }

  .container-buttons {
    font-family: 'Inter-Bold';

    .finalize-button {
      width: 100%;
      background-color: $black-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
    }

    .valid-sale {
      background-color: $green-color;
    }
  }

  .payment-method {
    cursor: default;
    width: 100%;

    .name {
      color: white;
      background-color: $black-color-2;
      border-radius: 5px;
      margin-bottom: 5px;
      text-align: left;
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-transform: uppercase;
      padding-left: 5px;
      padding-right: 5px;
      height: 24px;
    }

    &.paycash .name {
      background-color: $orange-color-3;

      div {
        padding-left: 20px;

        &:before {
          content: '';
          width: 13px;
          height: 13px;
          position: absolute;
          left: 0;
          top: 2px;
          border-radius: 50%;
          background-color: #ffffff96;
        }
      }
    }

    &.paytpv .name {
      background-color: $blue-color-2;

      div {
        padding-left: 20px;

        &:before {
          content: '';
          width: 16px;
          height: 12px;
          position: absolute;
          left: 0;
          top: 2px;
          border-radius: 3px;
          background-color: #ffffff96;
        }
      }
    }

    .container-amount {
      position: relative;

      input {
        font-family: 'Inter-SemiBold';
        width: 100%;
        background-color: white;
        color: $black-color-2;
        font-size: 16px;
        outline: none;
        border: none;
        border-radius: 5px;
        height: 40px;
        padding-left: 15px;
      }

      ::placeholder {
        font-family: 'Inter-Regular';
        font-size: 14px;
      }
    }
  }

  .container-money {
    cursor: default;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    color: $black-color;
    font-family: 'Inter-SemiBold';

    @media (max-width: 1024px) {
      margin-left: 10px;
    }

    .title {
      font-size: 14px;
    }

    .value {
      font-size: 22px;
    }
  }

  .container-change {
    border: 1px solid $red-color;
  }

  .container-balance {
    border: 1px solid $green-color;
  }

  .save-balance {
    label {
      font-size: 12px;
      text-transform: uppercase;
      color: $black-color;
    }

    input {
      background-color: $green-color;
    }
  }

  .alert {
    background-color: $red-color;
    color: $white-color;
  }

  .success {
    background-color: $green-color;
    color: $white-color;
  }

  .inactive {
    border: 1px solid $gray-color-3;
    background-color: transparent;
  }

  .container-options {
    background-color: white;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color-2;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    width: 170px;
    cursor: pointer;
  }

  .changes {
    text-align: center;
    font-size: 16px;
    font-family: 'Inter-SemiBold';
    color: white;
    background-color: $blue-color-2;
    border-radius: 5px;
    vertical-align: middle;
    height: 64px;
    line-height: 64px;
    cursor: pointer;

    @media (max-height: 740px) {
      height: 54px;
      line-height: 54px;
    }
  }

  .change-status {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;
  }

  select {
    background-color: white;
    width: 100%;
    height: 40px;
    border: 1px solid $gray-color-2;
    border-radius: 10px;
    padding-left: 20px;
    color: $gray-color-2;
    font-size: 14px;
    font-family: 'Inter-SemiBold';
  }
}

.sale-payment {
  h1 {
    font-size: 22px;
  }
  .cancel-reservation {
    font-size: 10px;
    font-family: 'Inter-SemiBold';
    color: white;
    background-color: $red-color;
    border-radius: 6px;
    height: 25px;
    width: 70%;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    text-transform: uppercase;
    outline: none;
  }
  .subtitle {
    font-family: 'Inter-Regular';
    font-size: 12px;
    color: $gray-color;
  }
  .container-buttons {
    margin-top: 30px;
    font-family: 'Inter-Bold';
    .finalize-button {
      width: 100%;
      background-color: $black-color;
      border: none;
      height: 44px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
    }
    .valid-return {
      background-color: $red-color;
    }
    .valid-sale {
      background-color: $green-color;
    }
  }
  .payment-method {
    width: 100%;
    cursor: default;

    .name {
      color: white;
      background-color: $black-color-2;
      border-radius: 5px;
      margin-bottom: 5px;
      text-align: left;
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      text-transform: uppercase;
      padding-left: 5px;
      padding-right: 5px;
      height: 24px;
    }

    .container-amount {
      position: relative;

      input {
        font-family: 'Inter-SemiBold';
        width: 100%;
        background-color: white;
        color: $black-color-2;
        font-size: 16px;
        outline: none;
        border: none;
        border-radius: 5px;
        height: 40px;
        padding-left: 15px;
      }

      ::placeholder {
        font-family: 'Inter-Regular';
        font-size: 14px;
      }
    }
  }

  .container-money {
    cursor: default;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    color: $black-color;
    font-family: 'Inter-SemiBold';

    @media (max-width: 1024px) {
      width: 100%;
      margin-left: 10px;
    }

    .title {
      font-size: 14px;
    }

    .value {
      font-size: 22px;
    }
  }
  .save-balance {
    label {
      font-size: 12px;
      text-transform: uppercase;
      color: $black-color;
    }

    input {
      background-color: $green-color;
    }
  }

  .alert {
    background-color: $red-color;
    color: $white-color;
  }

  .success {
    background-color: $green-color;
    color: $white-color;
  }

  .inactive {
    border: 1px solid $gray-color-3;
    background-color: transparent;
  }

  .container-options {
    background-color: white;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color-2;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    width: 170px;
    cursor: pointer;
  }

  .changes {
    text-align: center;
    font-size: 16px;
    font-family: 'Inter-SemiBold';
    color: white;
    background-color: $blue-color-2;
    border-radius: 5px;
    vertical-align: middle;
    height: 64px;
    line-height: 64px;
    cursor: pointer;
  }
}

.pos-products-table-container {
  /*overflow-x: auto;*/

  .pos-products-table {
    .sale-checkbox {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }

    .remove-item {
      color: $black-color;
      cursor: pointer;
      margin-right: 10px;
    }

    .img-thumbnail {
      min-width: 33px;
      height: 50px;
      width: 50px;
      max-width: 50px;
      object-fit: cover;
    }

    .name {
      font-family: 'Inter-Bold';
      font-size: 12px;
      cursor: default;
      white-space: break-spaces;
    }

    .manual {
      color: $blue-color-2;

      & + span {
        width: 25px;
        height: 25px;
      }
    }

    .manual-product {
      width: 99%;
      font-size: 12px;
      line-height: 12px;
      padding: 5px;
      height: 26px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    .sku {
      font-family: 'Inter-Regular';
      font-size: 11px;
      color: $gray-color;
      cursor: default;
      @media (max-width: 1024px) {
        font-size: 10px;
      }
    }

    .replacedBy {
      font-family: 'Inter-Regular';
      font-size: 11px;
      color: $orange-color-3;
      cursor: default;
    }

    .price {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $gray-color;
      cursor: default;
    }

    .units {
      font-family: 'Inter-SemiBold';
      background-color: $black-color-2;
      color: white;
      padding: 3px 10px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 12px;
    }

    .units-icon {
      cursor: pointer;
      color: $gray-color-2;
    }

    .with-value {
      background-color: $blue-color-2;
    }

    .totals {
      font-size: 12px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;
    }

    .total {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      cursor: default;
      background-color: $green-color;
      color: white;
    }

    .return {
      background-color: $red-color;
    }
  }
}

@include ellipsis-text-multiline(2);
@include ellipsis-text-multiline(3);

.discount {
  font-size: 12px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: 'Inter-SemiBold';
  color: $gray-color;
  color: white;
  background-color: $black-color-2;
  padding: 3px 5px;
  border-radius: 5px;
  width: 100%;
  display: inline-block;
}

.return {
  background-color: $red-color;
}

.pos-subtotals {
  cursor: default;
  color: $gray-color-2;
  font-family: 'Inter-SemiBold';
  font-size: 12px;

  .discount {
    font-family: 'Inter-SemiBold';
    background-color: $black-color-2;
    color: white;
    padding: 3px 10px;
    border-radius: 4px;
    cursor: pointer;
  }

  .total {
    color: $black-color;
    font-size: 16px;

    .title-total-sale {
      padding: 1.5px 0px 1.5px 0px;
    }

    .total-sale {
      color: white;
      background-color: #26c44b;
      border-radius: 5px;
      padding: 1.5px 10px 1.5px 10px;
      cursor: pointer;
    }
  }
}

.link-sale {
  color: $blue-color;
  text-decoration: none;
  position: relative;

  & + span {
    width: 25px;
    height: 25px;
  }
}

.sale-with-note {
  position: absolute;
  top: 0px;
}

.customer-view {
  overflow-y: auto;

  .edit {
    cursor: pointer;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $blue-color-2;
  }

  .customer-name {
    font-size: 22px;
    font-family: 'Inter-SemiBold';
    color: $black-color-2;
  }

  .container-info {
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;

    .table-container {
      thead {
        tr {
          line-height: 16px;
        }
      }
    }
  }

  .information {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color;
  }

  .title-input {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
  }

  input,
  textarea,
  select {
    background-color: white;
    height: 35px;
    border-radius: 7px;
    border: 1px solid $gray-color-2;
    color: $black-color;
    padding-left: 15px;
  }

  textarea {
    padding-top: 10px;
    height: 100px;
  }

  .marketing {
    border-radius: 7px;
    height: 36px;
    line-height: 36px;
    background-color: $green-color;
    color: white;
    padding-left: 15px;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
  }

  .not-accepted {
    background-color: $red-color;
  }

  .total-sales {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color;
  }

  .num-sales {
    font-family: 'Inter-Regular';
    font-size: 12px;
    color: $black-color;
  }

  .header-sales {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color;
    border-bottom: 1px solid $gray-color-3;
    padding-bottom: 8px;
  }

  .container-product-sale {
    .name {
      font-size: 14px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;
    }

    .sku {
      font-size: 12px;
      font-family: 'Inter-Regular';
      color: $gray-color;
    }

    .price {
      font-size: 14px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;
    }
  }

  .container-sale {
    font-family: 'Inter-Regular';
    font-size: 14px;
    color: $black-color;
    border-bottom: 1px solid $gray-color-3;
    padding-bottom: 8px;
    padding-top: 8px;

    .link-sale {
      font-family: 'Inter-SemiBold';
      text-decoration: none;
    }

    .total-sale {
      font-family: 'Inter-SemiBold';
    }
  }

  .save-changes {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    background-color: $green-color;
    color: white;
    border-radius: 6px;
    border: 1px solid $green-color;
  }

  .save-changes:disabled {
    background-color: $gray-color-2;
    border: 1px solid $gray-color-2;
    cursor: not-allowed;
  }

  .delete-product {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    background-color: $red-color;
    color: white;
    border-radius: 6px;
    border: 1px solid $red-color;
  }
}

.delivery-notes-container {
  .table-container {
    overflow-x: auto;

    table {
      tbody {
        tr {
          td:nth-child(2) {
            max-width: 200px !important;
            overflow-x: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }
      }
    }
  }
}

.delivery-note-view {
  overflow-y: auto;

  .create-button {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .create-expiration {
    background-color: $blue-color;
    border: 1px solid $blue-color;
  }

  .create-supplier {
    background-color: $green-color;
    border: 1px solid $green-color;
  }

  .title-input {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
  }

  .error {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $red-color;
  }

  .customer-name {
    font-size: 22px;
    font-family: 'Inter-SemiBold';
    color: $black-color-2;
  }

  .container-info {
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;

    .title {
      font-family: 'Inter-SemiBold';
      font-size: 16px;
      color: #252e3c;
    }

    span.icon-button:hover {
      svg {
        stroke: $green-color;
      }
    }
  }

  input,
  textarea,
  select {
    background-color: white;
    height: 35px;
    border-radius: 7px;
    border: 1px solid $gray-color-2;
    color: $black-color;
    padding-left: 15px;
  }

  textarea {
    padding-top: 10px;
    height: 100px;
  }

  input.filled,
  textarea.filled,
  select.filled {
    border: 2px solid $green-color;
  }

  input.error,
  textarea.error,
  select.error {
    border: 2px solid $red-color;
  }

  input.error::placeholder,
  textarea.error::placeholder {
    color: $red-color;
    font-family: 'Inter-Regular';
  }

  .add-register {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
    background-color: $green-color;
    border: 1px solid $green-color;
    height: 30px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .delete-delivery-note {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
    background-color: transparent;
    border: 1px solid $gray-color-2;
    border-radius: 6px;
    padding-left: 30px;
    padding-right: 30px;
    height: 35px;
  }

  .confirm-delivery-note {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
    background-color: $green-color;
    border: 1px solid $green-color;
    border-radius: 6px;
    padding-left: 30px;
    padding-right: 30px;
    height: 35px;
  }

  .save-delivery-note {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
    background-color: $blue-color-2;
    border: 1px solid $blue-color-2;
    border-radius: 6px;
    padding-left: 30px;
    padding-right: 30px;
    height: 35px;
  }

  .delivery-note-status {
    width: 100%;
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    border-radius: 8px;
    padding-left: 15px;
    height: 35px;
    line-height: 35px;
  }

  .container-new-entry {
    margin-top: 10px;

    input,
    select {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .existing-product {
    background-color: #afdbff30;
  }

  .new-product {
    background-color: #aaf7bc30;
  }

  .error-product {
    background-color: #e5444430;
  }

  .img-thumbnail {
    width: 80px;
    max-width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .delivery-note-products {
    thead {
      tr {
        th.sku {
          width: 260px;
        }
        th.numeric-input {
          width: 90px;
        }
      }
    }
  }
}

.delivery-note-actions {
  padding-top: 10px;
  padding-right: 30px;

  .delete-delivery-note,
  .confirm-delivery-note,
  .save-delivery-note {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    border-radius: 6px;
    padding-left: 30px;
    padding-right: 30px;
    height: 35px;
  }

  .confirm-delivery-note:disabled,
  .save-delivery-note:disabled {
    background-color: $gray-color-2;
    border: 1px solid $gray-color-2;
    cursor: no-drop;
  }

  .delete-delivery-note {
    color: $gray-color-2;
    background-color: transparent;
    border: 1px solid $gray-color-2;
  }

  .delete-delivery-note:disabled {
    background-color: $gray-color-2;
    border: 1px solid $gray-color-2;
    cursor: no-drop;
    color: white;
  }

  .confirm-delivery-note {
    color: white;
    background-color: $green-color;
    border: 1px solid $green-color;
  }

  .save-delivery-note {
    color: white;
    background-color: $blue-color-2;
    border: 1px solid $blue-color-2;
  }
}

.container-return {
  border-top: 1px solid $black-color-2;

  @media (max-width: 1024px) {
    table {
      margin-top: 10px;

      thead tr {
        line-height: 16px !important;
      }
    }
  }

  .return-info {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
  }

  a {
    font-family: 'Inter-Regular';
    font-size: 18px;
    text-decoration: none;
  }

  .date {
    font-family: 'Inter-Regular';
    font-size: 12px;
    color: $gray-color-2;
  }
}

.product-in-store {
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  color: $black-color-2;
}

input[type='checkbox'].my-checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  appearance: none;
  margin-right: 10px;
  background-color: white;
  outline: 0;
  border: 0;
  border: 1px solid $gray-color-2 !important;
  display: inline-block;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

input[type='checkbox'].my-checkbox:checked {
  background-color: $green-color;
  text-align: center;
  line-height: 15px;
  outline: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.mark-all {
  font-family: 'Inter-SemiBold';
  font-size: 12px;
  color: $blue-color-2;
  cursor: pointer;
}

.container-product-image {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid $gray-color-3;
  border-radius: 8px;

  &.first-image {
    flex-basis: 50%;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .remove-icon {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    font-family: 'Inter-Regular';
    font-size: 12px;
    height: 20px;
    width: 20px;
    background-color: $red-color;
    color: white;
    border-radius: 50%;
  }

  .remove-icon-small {
    height: 14px;
    width: 14px;
  }
}

.container-product-inventory {
  .title {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color;
  }

  .separtor {
    border-bottom: 1px solid $gray-color-3;
    margin-bottom: 10px;
  }
}

.col-half-offset {
  margin-left: 4.166666667%;
}

.text-success {
  color: $green-color !important;
}

.text-danger {
  color: $red-color !important;
}

.text-warning {
  color: $orange-color-3 !important;
}

.icon-cash {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $orange-color-3;
}

.icon-balance {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $green-color;
}

.icon-other {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $pink-color;
}

.icon-tpv {
  height: 12px;
  width: 20px;
  border-radius: 4px;
  background-color: $blue-color-2;
}

.detail-cash-register-view {
  .movements {
    background-color: white;

    table {
      thead {
        tr {
          border-bottom: 1px solid $gray-color-7;
          font-family: 'Inter-SemiBold';
          font-size: 12px;
          height: 45px;
          color: $gray-color-2;
          text-transform: uppercase;
          text-align: center;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $gray-color-7;

          td {
            font-family: 'Inter-Regular';
            font-size: 12px;
            color: $black-color-2;
            text-align: center;
            height: 45px;
          }

          .link-sale {
            a {
              font-family: 'Inter-SemiBold';
              font-size: 12px;
              color: $black-color-2;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .details {
    position: relative;
    padding-top: 15px;

    .container-title {
      h1 {
        font-size: 22px;
      }

      .title-incidence {
        font-family: 'Inter-Bold';
        font-size: 14px;
        color: $red-color;
      }

      .title-resolved {
        font-family: 'Inter-Bold';
        font-size: 14px;
        color: $orange-color-3;
      }

      button {
        font-family: 'Inter-SemiBold';
        font-size: 12px;
        border: 1px solid $blue-color;
        background-color: $blue-color;
        color: white;
        border-radius: 6px;
        height: 75%;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-right: 10px;
      }

      button:disabled {
        background-color: $gray-color-2;
        border: 1px solid $gray-color-2;
        cursor: no-drop;
      }

      .mark-as-resolved {
        border: 1px solid $orange-color-3;
        background-color: $orange-color-3;
      }
    }

    .container-details {
      .title {
        font-family: 'Inter-SemiBold';
        font-size: 12px;
        text-transform: uppercase;
        color: $gray-color-2;
        margin-bottom: 15px;
      }

      .body {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
        background-color: $gray-color-6;

        .date {
          font-family: 'Inter-SemiBold';
          font-size: 14px;
          color: $black-color-2;
        }

        .id {
          font-family: 'Inter-Bold';
          font-size: 14px;
          color: $black-color-2;
        }

        .container-info {
          font-family: 'Inter-Regular';
          font-size: 12px;
          color: $gray-color;

          &.__title {
            text-transform: uppercase;
            margin-top: 20px !important;
          }

          strong {
            font-family: 'Inter-Bold';
          }
        }

        .container-detail-totals {
          font-size: 12px;
          color: $black-color-2;

          .title-type {
            font-family: 'Inter-SemiBold';
          }

          .value {
            font-family: 'Inter-Medium';

            &.result {
              color: $green-color;
            }

            &.descuadre {
              color: $red-color;
            }
          }

          .icon-cash {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: $orange-color-3;
          }

          .icon-tpv {
            height: 12px;
            width: 20px;
            border-radius: 4px;
            background-color: $blue-color-2;
          }
        }
        .container-total {
          .type {
            font-family: 'Inter-SemiBold';
            font-size: 14px;
            color: $green-color;
          }

          .total {
            font-family: 'Inter-SemiBold';
            font-size: 14px;
            color: $black-color-2;
          }
        }
      }

      textarea {
        width: 100%;
        height: 70px;
        border-radius: 8px;
        border: 1px solid $gray-color-2;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: 'Inter-Regular';
        font-size: 12px;
        color: $gray-color-2;
        outline: none;
      }
    }

    .container-details-close {
      .title {
        font-family: 'Inter-SemiBold';
        font-size: 12px;
        text-transform: uppercase;
        color: $gray-color-2;
        margin-bottom: 15px;
      }

      .body {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
        color: white;
        background-color: $black-color;
        font-family: 'Inter-SemiBold';

        .cash-icon {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: $orange-color-3;
        }

        .tpv-icon {
          height: 12px;
          width: 20px;
          border-radius: 4px;
          background-color: $blue-color-2;
        }

        .mismatch {
          color: $green-color;
        }
      }
    }

    .container-actions {
      position: absolute;
      bottom: 0;
      width: 100%;

      @media (max-width: 1024px) {
        bottom: 0;
      }
    }

    .update-and-resolve {
      background-color: $orange-color-3;
    }
  }
}

.inventories-view {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;

  .container-header {
    font-family: 'Inter-SemiBold';

    .back {
      font-size: 14px;
      color: $gray-color-2;
    }

    .title {
      font-size: 22px;
      color: $black-color-2;
    }
  }

  .container-info {
    background-color: white;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;

    .date {
      font-family: 'Inter-SemiBold';
      color: $black-color-2;
      margin-right: 30px;
    }

    .store {
      margin-right: 30px;
      font-family: 'Inter-Regular';
      color: $black-color-2;

      strong {
        font-family: 'Inter-SemiBold';
      }
    }

    .user {
      margin-right: 30px;
      font-family: 'Inter-Regular';
      color: $black-color-2;

      strong {
        font-family: 'Inter-SemiBold';
      }
    }
    .incidence {
      margin-right: 30px;
      font-family: 'Inter-Regular';
      color: $black-color-2;

      strong {
        font-family: 'Inter-SemiBold';
        color: $red-color;
      }
    }

    .fixed-incidence {
      margin-right: 30px;
      font-family: 'Inter-Regular';
      color: $black-color-2;

      strong {
        font-family: 'Inter-SemiBold';
        color: $orange-color-3;
      }
    }
  }

  input,
  select {
    border: 1.5px solid $gray-color-2;
    border-radius: 8px;
    padding-left: 10px;
    height: 30px;
    font-family: 'Inter-SemiBold';
    color: $gray-color-2;
    font-size: 12px;
    background: transparent;
    outline-width: 0;
  }
  input {
    width: 100%;
  }
  select {
    width: 18%;
  }

  .select-mode {
    width: 175px;
  }
  .input-mode {
    width: 175px;
  }

  .container-table {
    overflow-y: auto;
    height: 80vh;

    @media (max-width: 1024px) {
      height: 70vh;
    }

    table {
      width: 100%;
      font-size: 12px;

      tr {
        border-bottom: 1px solid $gray-color-3;
        height: 48px;

        th {
          position: sticky;
          top: 0;
          background-color: #f2f2f2;
        }
      }

      thead {
        tr {
          font-family: 'Inter-SemiBold';
          color: $gray-color-2;
        }
      }

      tbody {
        font-family: 'Inter-Regular';
        color: $gray-color;

        tr {
          .container-product {
            font-family: 'Inter-SemiBold';
            color: $black-color;
            font-size: 14px;

            img {
              height: 35px;
              width: 35px;
              background-color: white;
              border-radius: 6px;
            }
          }
        }
      }
    }

    .container-units {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $black-color;

      input {
        background-color: white;
        outline-width: 0;
        border: 1px solid white;
        border-radius: 6px;
        width: 60px;
        text-align: center;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }

    .filled-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $blue-color-2;
      text-align: center;
    }

    .empty-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $gray-color-3;
      text-align: center;
    }

    .same-quantity-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $green-color;
      text-align: center;
    }
    .incidence-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $red-color;
      text-align: center;
    }

    .fixed-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $orange-color-3;
      text-align: center;
    }
  }

  .container-buttons {
    .delete-button {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $gray-color-2;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 0;
      width: auto;
      color: #fff;
    }
    .draft-button {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $white-color;
      border: 1px solid $blue-color-2;
      background-color: $blue-color-2;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .finalize-button {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: white;
      background-color: $green-color;
      border: 1px solid $green-color;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .draft-button:disabled {
      background-color: $gray-color-2;
      border: 1px solid $gray-color-2;
      cursor: no-drop;
    }

    .finalize-button:disabled {
      background-color: $gray-color-2;
      border: 1px solid $gray-color-2;
      cursor: no-drop;
    }

    .reset-values {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: white;
      border: 1px solid $orange-color-2;
      background-color: $orange-color-2;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-right: 5px;
    }

    .initialize-values {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: white;
      border: 1px solid $blue-color-2;
      background-color: $blue-color-2;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-right: 5px;
    }
  }

  .counted-units-incidence {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $red-color;
  }
}

.preview-image {
  background-color: white;
  border-radius: 15px;
  img {
    height: 450px;
    width: 450px;
  }
}

.pending-transfer-view {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  .header {
    background-color: none !important;
    .title {
      font-family: 'Inter-SemiBold';
      font-size: 22px;
      color: $black-color-2;
    }
  }
  .container-info {
    .container-details {
      background-color: white;
      border-radius: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 25px;
      padding-right: 25px;
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      .title-input {
        color: $gray-color-2;
        margin-bottom: 5px;
        margin-bottom: 5px;
      }
      input,
      select {
        color: $black-color-2;
        border: 1px solid $gray-color-2;
        border-radius: 6px;
        height: 35px;
        padding-left: 10px;
      }
      input:disabled,
      select:disabled {
        cursor: not-allowed;
        background-color: $gray-color-6;
      }
      .container-status {
        color: white;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 8px;
      }
    }
  }
  .container-table {
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    table {
      thead {
        font-family: 'Inter-SemiBold';
        font-size: 12px;
        color: $gray-color-2;
        border-bottom: 1px solid $gray-color-8;
        height: 50px;
      }
      tbody {
        font-family: 'Inter-Regular';
        font-size: 12px;
        color: $gray-color;
        tr {
          height: 48px;
          border-bottom: 1px solid $gray-color-8;
          .product-name {
            font-family: 'Inter-SemiBold';
            font-size: 12px;
            color: $black-color;
          }
          .product-sku {
            font-family: 'Inter-Regular';
            font-size: 10px;
            color: $gray-color;
          }
          input {
            background-color: white;
            outline-width: 0;
            border: 1px solid $gray-color-3;
            border-radius: 6px;
            width: 70px;
            height: 30px;
            text-align: center;
            font-family: 'Inter-SemiBold';
            font-size: 12px;
            color: $black-color;
          }
        }
      }
    }
  }
  .container-resume {
    cursor: default;
    background-color: $black-color;
    color: white !important;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 12px;

    table {
      thead {
        tr {
          height: 30px;
          border-bottom: 1px solid $gray-color-2;
        }
      }

      tbody {
        tr {
          color: white !important;
          height: 45px;
        }
      }
    }

    .container-data-resume {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 8px;
      margin-left: 10px;
      margin-right: 10px;
      border: 1.5px solid $gray-color-2;
    }

    .container-number {
      border: 1px solid $green-color;
      font-family: 'Inter-SemiBold';
    }
  }

  .container-buttons {
    font-family: 'Inter-SemiBold';
    font-size: 14px;

    .save-transfer {
      color: white;
      background-color: $green-color;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 25px;
      padding-right: 25px;
      outline: none;
      border-radius: 8px;
      border: 1px solid $green-color;
    }

    .verify-transfer {
      color: white;
      background-color: $blue-color;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 25px;
      padding-right: 25px;
      outline: none;
      border-radius: 8px;
      border: 1px solid $blue-color;
    }

    .save-transfer:disabled {
      background-color: $gray-color-2;
      border: 1px solid $gray-color-2;
      cursor: no-drop;
    }

    .delete-transfer {
      color: $gray-color-2;
      background-color: transparent;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 50px;
      padding-right: 50px;
      outline: none;
      border-radius: 8px;
      border: 1px solid $gray-color-2;
    }
  }

  .create-expiration {
    background-color: $blue-color;
    border: 1px solid $blue-color;
  }
}

.transfer-draft {
  background-color: $gray-color-2;
}

.transfer-pending {
  background-color: $orange-color-3;
}

.transfer-incidence {
  background-color: $red-color;
}

.transfer-completed {
  background-color: $green-color;
}

.transfer-resolved {
  background-color: $green-color-2;
}

.verify-transfer-view {
  .title {
    font-family: 'Inter-SemiBold';
    font-size: 22px;
    color: $black-color-2;
  }
  .subtitle {
    font-family: 'Inter-Regular';
    font-size: 14px;
    color: $gray-color;
    strong {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $black-color-2;
    }
  }

  .create-button {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .create-expiration {
    background-color: $blue-color;
    border: 1px solid $blue-color;
  }

  .container-info {
    background-color: white;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;

    .date {
      font-family: 'Inter-SemiBold';
      color: $black-color-2;
      margin-right: 30px;
    }

    .store {
      margin-right: 30px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;

      strong {
        font-family: 'Inter-SemiBold';
      }
    }

    .user {
      margin-right: 30px;
      font-family: 'Inter-Regular';
      color: $black-color-2;
      strong {
        font-family: 'Inter-SemiBold';
      }
    }
    .incidence {
      margin-right: 30px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;

      strong {
        color: $red-color;
      }
    }
    .fixed-incidence {
      margin-right: 30px;
      font-family: 'Inter-SemiBold';
      color: $black-color-2;

      strong {
        color: $orange-color-3;
      }
    }
  }

  .container-filters {
    input,
    select {
      border: 1.5px solid $gray-color-2;
      border-radius: 8px;
      padding-left: 10px;
      height: 30px;
      font-family: 'Inter-SemiBold';
      color: $gray-color-2;
      font-size: 12px;
      background: transparent;
      outline-width: 0;
    }

    input {
      width: 100%;
    }

    select {
      width: 18%;
    }
  }

  .container-table {
    table {
      width: 100%;
      font-size: 12px;

      tr {
        border-bottom: 1px solid $gray-color-3;
        height: 48px;
      }

      thead {
        tr {
          font-family: 'Inter-SemiBold';
          color: $gray-color-2;
        }
      }

      tbody {
        font-family: 'Inter-Regular';
        color: $gray-color;

        tr {
          .container-product {
            font-family: 'Inter-SemiBold';
            color: $black-color;
            font-size: 14px;

            img {
              height: 35px;
              width: 35px;
              background-color: white;
              border-radius: 6px;
            }
          }
        }
      }
    }

    .container-units {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $black-color;

      input {
        background-color: white;
        outline-width: 0;
        border: 1px solid white;
        border-radius: 6px;
        width: 60px;
        text-align: center;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }

    .transfer-product-status-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      text-align: center;
    }

    .filled-icon {
      background-color: $blue-color-2;
    }

    .empty-icon {
      background-color: $gray-color-3;
    }

    .same-quantity-icon {
      background-color: $green-color;
    }

    .incidence-icon {
      background-color: $red-color;
    }

    .fixed-icon {
      background-color: $orange-color-3;
    }

    select {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      background-color: $black-color;
      color: white;
      border-radius: 8px;
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .container-buttons {
    .delete-button {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $gray-color-2;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 0;
      width: auto;
      color: #fff;
    }

    .finalize-button {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: white;
      background-color: $green-color;
      border: 1px solid $green-color;
      border-radius: 6px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .finalize-button:disabled {
      background-color: $gray-color-2;
      border: 1px solid $gray-color-2;
      cursor: no-drop;
    }
  }
}

@media (max-width: 1024px) {
  .position-relative {
    position: relative !important;
  }
}

input.input-filter,
select.input-filter {
  font-size: 14px;
  border: 1px solid $gray-color-9;
  border-radius: 5px;
  height: 38px;
  padding-left: 10px;
  color: $gray-color-2;
}

input.input-filter:focus,
select.input-filter:focus {
  outline: none;
}

.products-view {
  .infinite-scroll-component {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .product-calculations {
    margin-left: 20px;

    span {
      font-size: 12px;
      color: $black-color;
      font-weight: 600;
    }

    span:not(:last-child) {
      margin-right: 7px;
      padding-right: 7px;
      border-right: 1px solid $gray-color-3;
    }
  }

  .layout-selection {
    .layout-icon {
      cursor: pointer;
    }
  }

  .check-totals {
    font-family: 'Inter-SemiBold';
    font-size: 13px;
    color: $gray-color-2;
  }

  .infinite-scroll-no-data {
    margin-top: 10px;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    text-align: center;
    color: $gray-color-2;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-color-3;
  }

  .img-thumbnail {
    width: 50px !important;
    max-width: 50px;
    height: 50px !important;
    object-fit: cover;
  }

  .product-finder-list {
    .product-card {
      .img-thumbnail {
        height: 250px !important;
        object-fit: cover;
        width: 100% !important;
      }

      .product-card-info {
        margin-top: 5px;

        .name {
          font-size: 14px;
          line-height: 20px;
        }

        .sku {
          font-size: 12px;
          line-height: 18px;
        }

        .price {
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .num-products {
    color: $gray-color-2;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
  }
}

.notifications {
  background-color: $white-color;
  width: 290px;
  border-radius: 6px;

  .header {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 10px;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: white;
    border-bottom: 1px solid $gray-color-3;
  }

  .body {
    max-height: 84vh;
    overflow-y: auto;

    .no-notifications {
      font-family: 'Inter-Regular';
      font-size: 12px;
      color: $gray-color-2;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-color-3;
      padding-left: 15px;
      padding-right: 10px;
    }

    .notification {
      padding-top: 5px;
      padding-left: 15px;
      padding-right: 10px;
      border-bottom: 1px solid $gray-color-3;

      .content {
        .title {
          font-family: 'Inter-SemiBold';
          font-size: 12px;
          color: $black-color;
          padding-top: 5px;
        }

        .date {
          font-family: 'Inter-Regular';
          font-size: 9px;
          color: #6e6b6b;
          padding-top: 2px;
        }

        .description {
          font-family: 'Inter-Regular';
          font-size: 10px;
          color: $black-color;
          padding-top: 5px;
        }

        .link {
          margin-bottom: 10px;

          a {
            font-family: 'Inter-SemiBold';
            font-size: 12px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .footer {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    text-align: center;

    a {
      text-decoration: none;
      color: $gray-color-2;
    }

    a.active {
      color: $black-color;
    }
  }
}

.repair-details {
  .repair-container {
    .check-switch {
      width: 115px;
    }
  }

  .budget-container {
    background-color: white;
    padding: 20px 12px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }

  .budget-resume {
    .title {
      font-weight: 'Inter-SemiBold';
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }

    .content {
      background-color: $gray-color-6;
      border-radius: 8px;
      padding: 10px;
      font-family: 'Inter-SemiBold';
      font-size: 14px;

      .key {
        color: $gray-color-2;
      }

      .value {
        color: $black-color;
      }
    }
  }

  .title {
    font-family: 'Inter-SemiBold';
    font-size: 22px;
    color: $black-color-2;
  }

  .budget-subtitle {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color;
  }

  .subtitle {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
  }

  .repair-status {
    width: 100%;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
  }

  .no-images {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $black-color-2;
  }

  input,
  select,
  textarea {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;
    border: 1px solid $gray-color-2;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }

  .input-size {
    height: 38px;
  }

  textarea {
    height: 150px;
    padding-top: 15px;
    padding-left: 15px;
  }

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid $gray-color-2;
  }

  input.error,
  textarea.error,
  select.error {
    border: 2px solid $red-color;
  }

  input.error::placeholder,
  textarea.error::placeholder {
    color: $red-color;
    font-family: 'Inter-Regular';
  }

  ::placeholder {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-3;
  }

  .no-data-description {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;

    a {
      text-decoration: none;
    }
  }

  .resume {
    font-family: 'Inter-SemiBold';

    .title {
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }

    .body {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $gray-color-6;
      border-radius: 8px;

      .date {
        font-size: 14px;
        color: $black-color-2;
      }

      .repair-id {
        font-family: 'Inter-Bold';
        font-size: 14px;
        color: $black-color-2;
      }
      .store-title {
        font-size: 12px;
      }

      .store-name {
        font-family: 'Inter-Regular';
        font-size: 12px;
        color: $black-color;
      }

      label {
        color: $gray-color-2;
        font-size: 14px;
      }

      .value {
        font-size: 14px;
        color: $black-color;
      }

      .technical-service-info {
        font-size: 12px;
        color: $blue-color-2;
        text-decoration: none;
      }
    }
  }
  .new-action {
    font-family: 'Inter-Bold';
    font-size: 14px;
    background-color: $black-color-2;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    outline: none;
    border: none;
  }

  .new-action:disabled {
    background-color: $gray-color-2;
  }

  .repair-records {
    .title {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }

    table {
      white-space: normal;
    }
  }

  .create-technical-service {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    border: 1px solid $blue-color-2;
    background-color: $blue-color-2;
    color: white;
    border-radius: 6px;
    height: 75%;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container-image {
  border: 1px solid $gray-color-7;
  border-radius: 15px;
  height: 64px;
  width: 64px;
  .product-image {
    border: 1px solid $gray-color-3;
    border-radius: 15px;
    height: 64px;
    width: 64px;
  }
  .delete-image {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $red-color;
    color: $white-color;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    svg {
      padding-bottom: 2px;
    }
  }
}

.repair-customer {
  position: relative;
  padding-top: 15px;

  .container-actions {
    position: absolute;
    bottom: 0;
    width: 100%;

    @media (max-width: 1024px) {
      bottom: 0;
    }
  }

  .title {
    font-family: 'Inter-SemiBold';
    font-size: 22px;
    color: $black-color-2;
  }

  .subtitle {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
  }

  .notes {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    cursor: pointer;
  }

  .has-notes {
    background-color: $red-color;
    font-size: 12px;
    cursor: pointer;
  }

  .container-contact-group {
    background-color: white;
    padding: 20px 12px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);

    .title {
      font-family: 'Inter-SemiBold';
      font-size: 16px;
      color: $black-color;
    }

    .container-contact {
      text-align: center;
      color: $gray-color;
      font-family: 'Inter-SemiBold';
      font-size: 13px;
      border: 1px solid $gray-color;
      border-radius: 8px;
      padding: 20px;
      width: 100%;
      cursor: pointer;

      a {
        color: $gray-color;
        text-decoration: none;
      }

      &:hover {
        border: none;
        background-color: $green-color;
        color: white;

        a {
          color: white;
        }
      }
    }
  }

  .repair-button {
    font-family: 'Inter-SemiBold';
    font-size: 18px;
    height: 50px;
    color: white;
    outline: none;
    border: none;
    text-transform: uppercase;
  }

  .repair-button:disabled {
    background-color: $gray-color;
  }

  .cancel-button {
    width: 35%;
    border-right: 2px solid white;
    background-color: $black-color;
  }

  .button-repair {
    width: 65%;
    background-color: $green-color;
  }

  .button-repair.disabled {
    background-color: $gray-color-2;
  }
  .cancel-repair {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    background-color: $red-color;
    color: white;
    border-radius: 6px;
    border: none;
    padding: 2px 8px;
  }
}

.repairs {
  .status {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    padding: 5px 14px;
    border-radius: 12px;
  }
}

.gastos {
  .table-container {
    table {
      tbody {
        tr {
          td:nth-child(5) {
            white-space: pre-wrap;
            width: 100%;
          }
        }
      }
    }
  }
}

.categories {
  .table-container {
    .expander {
      background-color: $black-color;
      border-radius: 5px;
      width: 26px;
      display: block;
      text-align: center;
      height: 26px;
      line-height: 23px;

      &.active {
        background-color: transparent;
        border: 1px solid $gray-color-2;
      }
    }
  }
}

.ticket-options {
  background-color: white;
  padding: 20px 16px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);

  .title {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color;
  }
  .container-contact {
    text-align: center;
    background-color: white;
    color: $gray-color;
    border: 1px solid $gray-color;
    font-family: 'Inter-SemiBold';
    font-size: 13px;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    cursor: pointer;

    @media (max-width: 1024px), (max-height: 740px) {
      min-height: 85px;
      padding: 10px;
    }

    a {
      color: $gray-color;
      text-decoration: none;
    }
  }

  .container-contact:hover {
    border: none;
    background-color: $green-color;
    color: white;

    a {
      color: white;
    }
  }
}

#toast-container {
  div {
    opacity: 1 !important;
    border-radius: 10px;
  }

  .toast-error {
    background-color: $red-color !important;
  }

  .toast-info {
    background-color: #0092fd !important;
  }

  .toast-close-button {
    color: $white-color !important;
    opacity: 1 !important;
    font-weight: 100;
  }
}

.table-booking-payments {
  background-color: white;

  .my-table {
    thead {
      tr {
        th {
          padding-left: 5px;
          line-height: 3;
          background: rgb(248, 249, 250);
        }
      }
    }

    tbody {
      tr {
        td {
          padding-left: 5px;
        }
      }
    }
  }
}

.label-input-date {
  font-size: 11px;
  padding-left: 5px;
}

.input-date {
  width: 100%;
  border: 1px solid $gray-color-2;
  border-radius: 7px;
  height: 34px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

.react-datepicker__tab-loop {
  z-index: 2;
}

.sales {
  table {
    tbody {
      tr {
        td {
          position: relative;
        }
      }
    }
  }

  .filter-notes {
    font-family: 'Inter-SemiBold';
    font-size: 13px;
  }
}

.prev-next {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.prev-next.disabled {
  cursor: not-allowed;
}

.text-bold {
  font-family: 'Inter-SemiBold';
}

.container-selected-date-range {
  background-color: white;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  /* width: 245px; */

  .selected-date-range {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color;
  }
}

.date-ranges-options {
  .date-option {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    padding: 5px 14px;
    cursor: pointer;
  }

  .date-option:hover {
    background-color: $green-color;
    color: white;
  }

  .selected {
    background-color: $green-color;
    color: white;
  }

  .container-time {
    padding-left: 10px;
    padding-right: 10px;

    .time-option {
      line-height: 25px;
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      color: $gray-color-2;
      margin-right: 10px;
    }

    input[type='time'] {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $black-color-2;
      border: 1px solid $gray-color-2;
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: white !important;
    }

    button {
      background-color: transparent !important;
      border: 1px solid #26c44b !important;
      border-radius: 5px;

      svg {
        margin-bottom: 3px;
      }
      &:hover {
        background-color: #26c44b !important;

        svg {
          stroke: #fff !important;
        }
      }
    }
  }
}

.totals-wrapper {
  background-color: $black-color;
  border-radius: 7px;
  padding: 20px 0;

  .totals-info {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: $white-color;
    margin-left: 6px;
    margin-right: 6px;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
}

.new-repair {
  .product-side {
    background-color: white;
  }
}

.new-repair-header {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 18px;
    margin-top: 5px;
  }
  .in-warranty {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: white;
    background-color: $blue-color-2;
    width: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border-radius: 8px;
    float: right;
  }
}

.repair-product-with-warranty {
  color: white;
  font-family: 'Inter-SemiBold';
  font-size: 20px;
  text-transform: uppercase;
  background-color: $blue-color-2;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.repair-budget-not-accepted {
  color: white;
  font-family: 'Inter-SemiBold';
  font-size: 20px;
  text-transform: uppercase;
  background-color: $red-color;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.repair-product-warranty {
  .container-option {
    width: 100%;
    color: white;
    text-transform: uppercase;
    font-family: 'Inter-SemiBold';
    font-size: 18px;
    height: 180px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .under-warranty {
    background-color: $blue-color-2;
  }
  .no-warranty {
    background-color: $orange-color-3;
  }
}

.repair-customer-header {
  margin-bottom: 20px;
  h1 {
    font-size: 25px;
  }
  .notes {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    float: right;
    cursor: pointer;
  }
  .has-notes {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    background-color: $red-color;
    float: right;
    cursor: pointer;
    border-radius: 7px;
    color: white;
    padding: 3px 7px;
  }
}

.repair-update-header {
  margin-bottom: 20px;
  h1 {
    font-size: 20px;
  }
  .cancel-repair {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    background-color: $red-color;
    color: white;
    border-radius: 6px;
    border: none;
    padding: 2px 8px;
  }
  .notes {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    float: right;
    cursor: pointer;
  }
  .has-notes {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    background-color: $red-color;
    float: right;
    cursor: pointer;
    border-radius: 7px;
    color: white;
    padding: 3px 7px;
  }
}

.new-repair-buttons {
  .container-button {
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 20px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
  }
  .cancel {
    background-color: $black-color-2;
    width: 30%;
  }
  .open-repair {
    background-color: $green-color;
    width: 70%;
  }
  .open-repair.disabled {
    background-color: $gray-color-2;
    cursor: not-allowed;
  }
}

.update-repair-buttons {
  button {
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 20px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    width: 50%;
  }
  button.disabled {
    background-color: $gray-color-2 !important;
  }
  .pick-up-repair {
    background-color: $green-color;
  }
  .next {
    background-color: $green-color;
  }
  .back {
    background-color: $black-color;
  }
  .save {
    background-color: $blue-color;
  }
  .save.disabled {
    background-color: $black-color-2 !important;
  }
}

.repair-status-view,
.sale-status-view {
  color: white;
  font-family: 'Inter-SemiBold';
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.repair-warranty-in-process {
  button {
    background-color: $gray-color-2;
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    display: inline-block;
    width: auto;
  }
}

.repair-budget {
  label {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
    margin-bottom: 15px;
  }
  button {
    background-color: $gray-color-2;
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    outline: none;
    border: none;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  button.accept {
    background-color: $green-color;
  }
  button.decline {
    background-color: $red-color;
  }
}

.repair-notify {
  background-color: white;
  padding: 20px 16px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);

  .title {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color;
  }

  p {
    font-family: 'Inter-Regular';
    font-size: 14px;
    color: $gray-color-2;
  }

  .container-contact {
    color: $gray-color;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    border: 1px solid $gray-color;
    border-radius: 8px;
    padding: 20px 20px;
    width: 100%;
    cursor: pointer;

    a {
      color: $gray-color;
      text-decoration: none;
    }

    &:hover {
      border: none;
      background-color: $green-color;
      color: white;

      a {
        color: white;
      }
    }
  }
}

.repair-summary {
  margin-top: 10px;
  margin-bottom: 10px;

  input,
  select,
  textarea {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;
    border: 1px solid $gray-color-2;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background-color: white !important;
  }
  textarea {
    height: 150px;
    padding-top: 15px;
    padding-left: 15px;
  }

  input.error,
  select.error,
  textarea.error {
    border: 2px solid $red-color;
  }
  .error {
    color: $red-color;
  }

  .resume {
    font-family: 'Inter-SemiBold';

    .title {
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }

    .body {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $gray-color-6;
      border-radius: 8px;

      .date {
        font-size: 14px;
        color: $black-color-2;
      }

      .repair-id {
        font-family: 'Inter-Bold';
        font-size: 14px;
        color: $black-color-2;
      }
      .store-title {
        font-size: 12px;
      }

      .store-name {
        font-family: 'Inter-Regular';
        font-size: 12px;
        color: $black-color;
      }

      label {
        color: $gray-color-2;
        font-size: 14px;
      }

      .value {
        font-size: 14px;
        color: $black-color;
      }

      .technical-service-info {
        font-size: 12px;
        color: $blue-color-2;
        text-decoration: none;
      }
    }
  }

  .repair-records {
    .title {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }
  }

  .new-action {
    font-family: 'Inter-Bold';
    font-size: 14px;
    background-color: $black-color-2;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    outline: none;
    border: none;
  }
  .new-action:disabled {
    background-color: $gray-color-2;
  }

  .prices {
    font-size: 14px;
  }
}

.repair-prices {
  .title {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    color: $black-color;
  }

  .content {
    background-color: $gray-color-6;
    border-radius: 8px;
    padding: 10px;
    font-family: 'Inter-SemiBold';
    font-size: 14px;

    label {
      color: $gray-color-2;
      margin-bottom: 10px;
    }
  }

  input,
  select,
  textarea {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;
    border: 1px solid $gray-color-2;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }
  input.error,
  select.error,
  textarea.error {
    border: 2px solid $red-color;
  }

  .input-size {
    height: 38px;
  }
}

.repair-rejected-budget {
  color: white;
  background-color: $red-color;
  border-radius: 8px;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
}

.new-repair-product {
  background-color: $white-color;
  padding-top: 15px;

  .subtitle {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
  }

  .repair-status {
    width: 100%;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-family: 'Inter-SemiBold';
    font-size: 16px;
  }

  .use-stock {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;
    margin-bottom: 10px;
  }

  .no-images {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $black-color-2;
  }

  input,
  select,
  textarea {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;
    border: 1px solid $gray-color-2;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }

  .input-size {
    height: 38px;
  }

  textarea {
    height: 150px;
    padding-top: 15px;
    padding-left: 15px;
  }

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid $gray-color-2;
  }

  input.error,
  textarea.error,
  select.error {
    border: 2px solid $red-color;
  }

  input.error::placeholder,
  textarea.error::placeholder {
    color: $red-color;
    font-family: 'Inter-Regular';
  }

  ::placeholder {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-3;
  }

  .no-data-description {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $black-color-2;

    a {
      text-decoration: none;
    }
  }

  .resume {
    font-family: 'Inter-SemiBold';

    .title {
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }

    .body {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $gray-color-6;
      border-radius: 8px;

      .date {
        font-size: 14px;
        color: $black-color-2;
      }

      .repair-id {
        font-family: 'Inter-Bold';
        font-size: 14px;
        color: $black-color-2;
      }
      .store-title {
        font-size: 12px;
      }

      .store-name {
        font-family: 'Inter-Regular';
        font-size: 12px;
        color: $black-color;
      }

      label {
        color: $gray-color-2;
        font-size: 14px;
      }

      .value {
        font-size: 14px;
        color: $black-color;
      }

      .technical-service-info {
        font-size: 12px;
        color: $blue-color-2;
        text-decoration: none;
      }
    }
  }
  .new-action {
    font-family: 'Inter-Bold';
    font-size: 14px;
    background-color: $black-color-2;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    outline: none;
    border: none;
  }
  .new-action:disabled {
    background-color: $gray-color-2;
  }
  .repair-records {
    .title {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      color: $gray-color-2;
      text-transform: uppercase;
    }
  }
  .create-technical-service {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    border: 1px solid $blue-color-2;
    background-color: $blue-color-2;
    color: white;
    border-radius: 6px;
    height: 75%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container-budget {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: $gray-color-2;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid $gray-color-2;
    padding-left: 10px;
    border-radius: 6px;
    input {
      width: 15px;
      height: 15px;
    }
  }
  .container-budget.error {
    color: $red-color;
    border: 2px solid $red-color;
    input {
      outline: 1px solid $red-color;
      border: none !important;
    }
  }
}

.come-back {
  font-size: 12px;
  font-family: 'Inter-SemiBold';
  color: $gray-color-2;
  cursor: pointer;
  display: inline-block;

  svg {
    margin-top: 2px;
  }
}

.check-switch {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 55px;
  justify-content: flex-start;
  align-content: center;

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 50px;
    height: 25px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #808a9559;
    transition: background-color ease 0.3s;

    @media (max-width: 1024px) {
      width: 23px;
      height: 12px;
    }

    &:before {
      content: 'on off';
      display: block;
      position: absolute;
      z-index: 2;
      width: 21px;
      height: 21px;
      background: $white-color;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      font: 9px/23px Helvetica;
      text-transform: uppercase;
      font-weight: bold;
      text-indent: -21px;
      word-spacing: 30px;
      color: $white-color;
      text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
      white-space: nowrap;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;

      @media (max-width: 1024px) {
        width: 10px;
        height: 10px;
        left: 1px;
        top: 1px;
        text-indent: -130px;
      }
    }

    &:checked {
      background-color: $green-color;

      &:before {
        left: 27px;

        @media (max-width: 1024px) {
          left: 12px;
        }
      }
    }
  }

  label {
    font-family: 'Inter-SemiBold';
    font-size: 13px;
    color: #808a9559;
    text-align: center;
    margin-top: 5px;

    @media (max-width: 1024px) {
      font-size: 12px !important;
      line-height: 13px;
    }
  }

  &.switched {
    input[type='checkbox'] {
      background-color: $gray-color-2;

      &:checked {
        background-color: $green-color;
      }
    }

    label {
      color: $gray-color-2;
    }
  }
}

/* GRID */
.layout {
  display: grid;
  grid:
    'header header' auto
    'aside main' 1fr
    'aside main' 1fr
    / auto 1fr;
  height: 100vh;
  overflow: hidden;
}
.header {
  grid-area: header;
}
.aside {
  grid-area: aside;
}
.main {
  grid-area: main;
  height: calc(100vh - 43px);
  overflow-y: auto;
}

.inner-layout {
  display: grid;
  grid:
    'l-side r-side' 1fr
    / 1fr 1fr;
}
.l-side {
  grid-area: l-side;
  height: calc(100vh - 43px);
  overflow-y: auto;
}
.r-side {
  grid-area: r-side;
  height: calc(100vh - 43px);
  overflow-y: auto;
}

/* HEADER */
.header {
  background-color: $black-color-2;
  color: white;

  .container-header {
    font-family: 'Inter-Bold';
    color: $white-color;
    align-items: center;

    .logo {
      left: 0;
      text-align: left;
      margin-left: 5px;
      margin-right: 20px;

      img {
        max-width: 120px;
        width: 100%;
      }
    }

    .company {
      margin-left: 22.5px;
      margin-right: 22.5px;
      font-weight: 600;
    }

    .company-selector {
      margin-left: 10px;

      select {
        color: $black-color;
        background: $white-color;
        border-radius: 20px;
        border: 1.5px solid $white-color;
        width: 210px;
        height: 29px;
        padding-left: 7.5px;
        font-size: 14px;
        font-family: 'Inter-semibold';

        &:focus {
          outline: none;
        }
      }

      .store-wholesaler {
        color: $black-color;
      }
    }

    .no-stores {
      font-family: 'Inter-Light';
      font-size: 14px;
    }

    .withdrawals-button {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      border: 1px solid $orange-color-2;
      background-color: $orange-color-2;
      color: white;
      border-radius: 6px;
      height: 75%;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .repairs-button {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      border: 1px solid $green-color;
      background-color: $green-color;
      color: white;
      border-radius: 6px;
      height: 75%;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .close-pos {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      border: 1px solid $blue-color-2;
      background-color: $blue-color-2;
      color: white;
      border-radius: 6px;
      height: 75%;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .notification-badge {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .container-settings {
      font-family: 'Inter-Medium';
      font-size: 14px;
      min-width: $header-width;
      display: inline-block;
      height: 100%;
      border-right: 1.5px solid $gray-color-3;
      ul {
        margin-top: 20px;
        list-style-type: none;
        padding-left: 17px;
        color: $gray-color-2;
        li {
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
            color: $gray-color-2;
            img {
              margin-right: 14px;
            }
          }
          margin-top: 15px;
          margin-bottom: 15px;
        }
        li.active {
          a {
            font-family: 'Inter-Bold';
            color: $black-color;
          }
        }
      }
      ul.views {
        border-bottom: 1.5px solid $gray-color-3;
      }
    }
  }
}

.header-wholesaler {
  background-color: white;
  border-bottom: 1px solid #d3d3d3;
}

/* ASIDE */
.aside {
  font-family: 'Inter-Medium';
  font-size: 14px;
  min-width: 171px;
  border-right: 1.5px solid $gray-color-3;
  background: $background-color;
  z-index: 2;

  ul {
    margin-top: 20px;
    list-style-type: none;
    padding-left: 15px;
    color: $gray-color-2;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 31px;

      a {
        text-decoration: none;
        color: $gray-color-2;

        img {
          margin-right: 14px;
        }
      }

      span {
        padding-left: 10px;
      }
    }

    li.active {
      a {
        font-family: 'Inter-Bold';
        color: $black-color;
      }
    }

    &.views {
      margin-bottom: 0;
      overflow-y: auto;
    }
  }

  ul.menu-logout {
    margin-top: 0;
    padding-top: 1rem;
    border-top: 1px solid $gray-color-3;

    li {
      margin-bottom: 0;

      a {
        display: flex;
      }
    }
  }

  &.collapsed {
    max-width: 53px;
    overflow: hidden;
    min-width: 53px;
    width: 100%;

    ul {
      overflow: hidden;

      li {
        span {
          display: none;
        }
      }
    }
  }

  .has-submenu {
    .to-rotate {
      margin-left: 15px;
    }

    &.open {
      .to-rotate {
        transform: rotate(180deg);
      }
    }
  }
}

/* MAIN */
.main {
  // Filters
  .filters-bar {
    .sup-label {
      position: relative;

      span {
        position: absolute;
        top: -15px;
        left: 5px;
        font-size: 10px;
        color: #b9b9b9;
      }
    }
  }

  // Tables
  .table-container {
    overflow-x: auto;
  }

  table {
    white-space: nowrap;

    thead {
      tr {
        color: $gray-color-2;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;

        &:last-child {
          border-bottom: 1px solid $gray-color-2;
        }

        &:nth-child(2) {
          min-height: 40px;
          height: 50px;
        }
      }
    }

    tbody {
      tr {
        color: $black-color;
        font-size: 12px;
        border-bottom: 1px solid $gray-color-2;
        height: 50px;
      }
    }

    th,
    td {
      padding: 3px 5px;
    }
  }

  .infinite-scroll-component {
    table {
      thead {
        position: sticky;
        top: 0;
        background-color: $gray-color-8;
        z-index: 1;
      }
    }
  }

  // Inputs, Selects...
  input,
  select {
    background-color: transparent;
    border-radius: 8px;
  }

  .select-comp {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
      background-color: transparent;
      border-radius: 8px;
    }

    .css-1pahdxg-control,
    .css-1pahdxg-control:hover {
      border-color: $gray-color-7;
    }

    .css-26l3qy-menu {
      z-index: 99;
    }
  }
}

/* LEFT SIDE */
.l-side {
  background-color: $white-color;
}

/* RIGHT SIDE */
.r-side {
  & > div:first-child {
    height: 100%;
  }
}

.input-paginated-table {
  background: transparent;
  border: 1px solid $gray-color-2;
  border-radius: 7px;
  height: 30px;
  padding-left: 10px;
  color: $gray-color-2;
}

.cursor-pointer {
  cursor: pointer !important;
}

.invoice-view {
  padding-left: 20px;
  padding-right: 20px;
  .invoice-view-header {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #808a95;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    a {
      color: #808a95;
      text-decoration: none;
    }
    h1 {
      color: #222e3d;
      font-size: 22px;
      margin-left: 20px;
      margin-bottom: 0px;
    }
  }

  .white-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    label {
      color: #808a95;
      font-size: 14px;
      font-family: 'Inter-SemiBold';
      margin-bottom: 8px;
    }
  }

  input,
  textarea {
    border: 1px solid var(--unnamed-color-808a95);
    border: 1px solid #808a95;
    border-radius: 8px;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: #252e3c;
    font-size: 12px;
    height: 35px;
    padding-left: 12px;
  }

  input.is-invalid {
    border: 1px solid #e02760;
  }

  textarea {
    padding-top: 10px;
    height: 100px;
    font-family: 'Inter-Regular';
    color: #252e3c;
    font-size: 12px;
  }

  .invoice-status {
    border-radius: 8px;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: white;
    height: 35px;
    padding-left: 12px;
  }
  .posted {
    background-color: #26c44b;
  }
  .pending {
    background-color: #e09200;
  }

  button {
    border-radius: 8px;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
  }
  button:disabled {
    background-color: #808a95 !important;
    border: 1px solid #808a95 !important;
    color: white;
    cursor: not-allowed;
  }
  button.disabled {
    background-color: #808a95 !important;
    border: 1px solid #808a95 !important;
    color: white;
  }
  button.filled {
    background-color: #26c44b;
    border: 1px solid #26c44b;
    color: white;
  }
  button.outlined {
    background-color: transparent;
    border: 1px solid #808a95;
    color: #808a95;
  }

  .invoice-product-lines {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    table {
      width: 100%;
      thead {
        th.delete {
          width: 30px;
        }
        th.amount {
          width: 110px;
        }
        th.units,
        th.discount-product,
        th.vat {
          width: 90px;
        }
        th.equivalenceSurcharge {
          width: 40px;
        }
        th.total {
          width: 80px;
        }
      }
      tbody {
        tr {
          color: #252e3c;
        }
      }
    }
    input[type='number'],
    input[type='text'] {
      color: #252e3c;
      font-family: 'Inter-Regular';
      text-align: center;
      width: 100%;
    }
  }

  .invoice-totals {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    background-color: #252e3c;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    width: 75%;
    table {
      width: 100%;
      tr {
        color: white !important;
        text-align: center;
      }
    }
    input {
      color: white;
      font-family: 'Inter-Regular';
      text-align: center;
    }
  }

  .menu {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #cccccc;
    background-color: white;
    overflow-y: auto;
    z-index: 1;
  }
  .menu-loading {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .menu-items {
    max-height: 300px;
    overflow-y: auto;
  }
  .item {
    padding: 5px 10px;
    cursor: default;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
  }
  .item-highlighted {
    color: white;
    background-color: #4095bf;
  }
}

/* MOBILE display */
.hide-mobile {
  display: none;
}

.customers {
  .num-customers {
    color: $gray-color-2;
    font-size: 12px;
    font-family: 'Inter-SemiBold';
  }
}

.clear-filters {
  background-color: $blue-color;
  color: white;
  border: none;
  border-radius: 6px;
  height: 30px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  font-family: 'Inter-SemiBold';
  line-height: 14px;
}

.clear-filters:disabled {
  background-color: $gray-color-2;
  cursor: not-allowed;
}

.print-source {
  display: none;
}

@media print {
  .print-source {
    display: block;
  }
}

.thresholds {
  .nav-tabs {
    .nav-link {
      color: $gray-color-2;
      font-size: 12px;
      font-family: 'Inter-SemiBold';
      background-color: #d3d3d3;
      border-radius: 8px 8px 0px 0px;
      margin-right: 1px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .nav-link.active {
      border-color: #808a95;
      border-bottom-style: none;
      background-color: transparent !important;
      color: #252e3c;
      z-index: 1;
    }
  }
  .tab-content {
    border-left: 1px solid #808a95;
    border-right: 1px solid #808a95;
    border-bottom: 1px solid #808a95;
    padding: 14px 10px;
    border-radius: 0px 8px 8px 8px;
  }
  td {
    font-family: 'Inter-Bold';
    color: #808a95;
  }
}

.user-work-clockings-view {
  select {
    font-family: 'Inter-SemiBold';
    color: #252e3c;
    border: 1px solid #808a95;
    padding: 5px 10px;
  }
  select:focus {
    outline: none;
  }
  .container-header {
    h1 {
      font-family: 'Inter-Bold';
      font-size: 22px;
      color: #252e3c;
      font-weight: bold;
      margin-right: 10px;
    }
    h2 {
      font-family: 'Inter-Regular';
      font-size: 22px;
      color: #252e3c;
      font-weight: bold;
      margin-right: 10px;
    }
    .year-week {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      cursor: pointer;
      color: #252e3c;
    }
    select.user-time-sheet-status {
      font-family: 'Inter-SemiBold';
      font-size: 10px;
      color: white;
      background-color: #e09200;
      border: 1px solid #e09200;
      padding: 5px 14px;
      width: auto;
      border-radius: 14px;
    }
    select.user-time-sheet-status:focus {
      outline: none;
    }
    .user-time-sheet-status.completed {
      background-color: #26c44b;
      border: 1px solid #26c44b;
    }
    .container-year-month {
      background-color: white;
      padding: 5px;
      border-radius: 6px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      select {
        font-family: 'Inter-SemiBold';
        background-color: white;
        border: 1px solid white;
        color: #555555;
        font-size: 14px;
        outline: none;
      }
    }
  }
  .container-hours {
    div {
      padding: 8px 20px;
      background-color: #f8f9fa;
      border: 1px solid #d3d3d3;
      border-radius: 8px;
      margin-right: 20px;
      label {
        font-family: 'Inter-SemiBold';
        font-size: 14px;
        color: #252e3c;
        margin-right: 8px;
      }
      span {
        font-family: 'Inter-Regular';
        font-size: 14px;
        color: #252e3c;
      }
    }
  }
  .container-table {
    table {
      background-color: white;
      border: 1px solid #d3d3d3;
      width: 100%;
      thead {
        tr {
          border: none;
          line-height: 3;
        }
      }
      tbody {
        tr {
          border: 1px solid #d3d3d3;
          td {
            padding-bottom: 10px;
            padding-top: 10px;
            .container-day-month-weekday {
              padding-left: 10px;
              .day-month {
                font-family: 'Inter-SemiBold';
                font-size: 12px;
                color: #252e3c;
                display: inline-block;
              }
              .button-show-history {
                font-family: 'Inter-SemiBold';
                font-size: 11px;
                color: white;
                background-color: $blue-color;
                margin-top: 4px;
                border: none;
                border-radius: 5px;
                padding: 2px 8px;
              }
            }
            .container-input-times {
              margin-bottom: 10px;
              .input-times {
                font-family: 'Inter-SemiBold';
                color: #252e3c;
                border: 1px solid #808a95;
                padding: 5px 10px;
              }
              .input-times:focus {
                outline: none;
              }
              .modified {
                font-family: 'Inter-SemiBold';
                color: $orange-color-2;
              }
            }
            .invalid-uwc {
              border: 1px solid $red-color !important;
              color: 1px solid $red-color !important;
            }
            .valid-uwc {
              border: 1px solid $green-color !important;
              color: 1px solid $green-color !important;
            }
            .select-store {
              margin-bottom: 10px;
              width: 240px;
            }
            .container-worked-estimated-hours {
              margin-bottom: 5px;
              div {
                margin-bottom: 5px;
                label {
                  font-family: 'Inter-SemiBold';
                  font-size: 12px;
                  color: #252e3c;
                  margin-right: 5px;
                }
              }
            }
            .not-workable {
              font-family: 'Inter-SemiBold';
              font-size: 12px;
              color: white;
              background-color: #a0aec1;
              width: auto;
              padding: 4px 10px;
              border-radius: 8px;
            }
            .container-note {
              font-family: 'Inter-SemiBold';
              font-size: 12px;
              color: white;
              padding: 5px 8px;
              border-radius: 8px;
              margin-bottom: 10px;
              width: 55px;
              cursor: pointer;
            }
            .no-note {
              background-color: #808a95;
            }
            .with-note {
              background-color: #e02760;
            }
          }
        }
      }
    }
  }

  .missing-time {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: white;
    background-color: #e09200;
    width: auto;
    padding: 4px 10px;
    border-radius: 8px;
  }

  .container-workshifts {
    table {
      border-collapse: collapse;
      background-color: white;
      border: 1px solid #d3d3d3;
      width: 100%;
      thead {
        tr {
          line-height: 3;
          th {
            border: 1px solid #d3d3d3;
            width: 200px;
          }
          .worker {
            width: 150px;
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: top;
            padding-bottom: 10px;
            padding-top: 10px;
            border: 1px solid #d3d3d3;
            width: 200px;
          }
          .worker {
            width: 150px;
            .name {
              font-family: 'Inter-SemiBold';
              font-size: 12px;
              color: #252e3c;
            }
            .hours {
              font-family: 'Inter-Regular';
              font-size: 10px;
            }
          }
          .notes {
            position: absolute;
            right: 3px;
            top: 3px;
            height: 9px;
            width: 9px;
            border-radius: 50%;
            background-color: $red-color;
          }
          .week-day-workshift {
            .container-holiday-day-off {
              cursor: pointer;
              font-size: 10px;
              background-color: #a0aec1;
              border-radius: 8px;
              margin-bottom: 5px;
              padding: 7.5px 10px;
              height: 40px;
              position: relative;
              span {
                font-family: 'Inter-SemiBold';
                font-size: 10px;
                color: white;
              }
            }
            .container-user-year-month-week {
              cursor: pointer;
              font-size: 10px;
              border-radius: 8px;
              margin-bottom: 5px;
              padding: 7.5px 10px;
              position: relative;
              .in-out {
                font-family: 'Inter-SemiBold';
                font-size: 10px;
                color: #252e3c;
              }
            }
            .add-workshift {
              border-radius: 5px;
              border: 1px solid #d3d3d3;
              text-align: center;
              color: #d3d3d3;
              border-style: dashed;
              cursor: pointer;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .see-user-work-clockings {
    background-color: #808a95;
    padding: 6px 14px;
    border-radius: 8px;
    color: white;
    text-decoration: none;
    font-family: 'Inter-SemiBold';
    font-size: 12px;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.user-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.copy-sku-button {
  background-color: transparent;
  border-radius: 7px;
  border: none;
  height: 36px;
  width: 36px;
  color: white;
  text-decoration: none;
  font-family: 'Inter-SemiBold';
  font-size: 12px;
  cursor: pointer;
}

.react-html5-camera-photo {
  video,
  img {
    width: 100% !important;
  }
}

.centered-item {
  margin: 0 auto;
}

.purchase-order-status {
  border-radius: 8px;
  font-family: 'Inter-SemiBold';
  font-size: 12px;
  color: white;
  padding: 5px 15px;
  margin: 0 auto;
}
.purchase-order-status.draft {
  background-color: $orange-color;
}
.purchase-order-status.generated {
  background-color: $green-color;
}
.purchase-order-status.cancelled {
  background-color: $red-color;
}
.purchase-order-status.revision {
  background-color: $blue-color;
}
.purchase-order-status.deliveryNote {
  background-color: $pink-color;
}
.purchase-order-status.invoice {
  background-color: $green-color-2;
}

.new-purchase-order-view {
  .title {
    font-size: 22px;
    font-family: 'Inter-SemiBold';
    color: #222e3d;
  }

  .container-info {
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .title-input {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    color: $gray-color-2;
  }

  select {
    background-color: transparent;
    border-radius: 8px;
    height: 35px;
    border: 1px solid #808a95;
    color: #252e3c;
    padding-left: 15px;
  }

  table {
    input {
      width: 60px;
    }
    button {
      border-radius: 8px;
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 25px;
      padding-right: 25px;
      color: white;
    }
    img {
      width: 50px;
    }
    .add {
      background-color: $blue-color-2;
      border: 1px solid $blue-color-2;
    }
    .remove {
      background-color: $red-color;
      border: 1px solid $red-color;
    }
  }
}

.resume-totals {
  padding: 20px 25px;
  border-radius: 10px;
  color: white;
  background-color: $black-color;

  table {
    width: 100%;
    text-align: center;

    thead {
      tr {
        font-family: 'Inter-SemiBold';
        font-size: 12px;
        height: 35px;
      }
    }

    tbody {
      tr {
        font-family: 'Inter-Regular';
        font-size: 12px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        color: $white-color;

        td {
          div {
            border: 1px solid $gray-color-2;
            border-radius: 8px;
            height: 100%;
            padding-bottom: 5px;
            padding-top: 5px;
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .selected {
            font-family: 'Inter-SemiBold';
            border: 1px solid $green-color;
          }
        }
      }
    }
  }
}

.shopping-cart-purchase-order {
  width: 300px;
  padding: 15px 10px;

  .no-products {
    font-family: 'Inter-Regular';
    font-size: 12px;
  }

  .container-product {
    padding-top: 4px;
    padding-bottom: 6px;
    border-bottom: 1px solid #e7e9eb;

    .image-container {
      padding-right: 5px;
    }

    img {
      width: 50px;
    }

    .product-name {
      font-size: 11px;
    }
    .product-retail-price {
      font-size: 11px;
    }
    .container-units {
      background-color: #f7f7f7;
      border-radius: 3px;
      padding: 2px 5px;
      min-width: 65px;
      .product-units {
        font-size: 11px;
      }
    }

    .delete {
      font-size: 10px;
      margin-left: 15px;
      color: gray;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .container-total {
    margin-top: 10px;
    font-size: 14px;
  }

  .container-buttons {
    margin-top: 10px;
    font-size: 14px;
    button {
      color: white;
      width: 45%;
      border-radius: 8px;
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .save {
      background-color: $blue-color-2;
      border: 1px solid $blue-color-2;
    }
    .finalize {
      background-color: $green-color;
      border: 1px solid $green-color;
    }
  }
}

.purchase-order-view {
  .container-title {
    margin-left: 40px;
    h1 {
      font-size: 22px;
      margin-bottom: 0px;
    }
    .datetime {
      font-family: 'Inter-SemiBold';
      font-size: 12px;
      color: #808a95;
    }
  }

  .final-units {
    width: 80px;
  }

  .product-image {
    width: 50px;
  }

  .container-info {
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    .title {
      font-family: 'Inter-SemiBold';
      font-size: 14px;
      color: $gray-color-2;
    }
    .value {
      font-family: 'Inter-Regular';
      font-size: 14px;
      color: #252e3c;
    }
    input {
      background-color: transparent;
      border-radius: 8px;
      height: 35px;
      border: 1px solid #808a95;
      color: #252e3c;
      padding-left: 15px;
      min-width: 100%;
    }
    input.error {
      border: 1px solid $red-color;
    }
  }

  .modify-draft-purhcase-order-button {
    background-color: $blue-color-2;
    border: 1px solid $blue-color-2;
    border-radius: 8px;
    height: 35px;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    text-decoration: none;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    margin-right: 10px;
  }

  .finalize-button {
    background-color: $green-color;
    border: 1px solid $green-color;
    border-radius: 8px;
    height: 35px;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    text-decoration: none;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
  }

  .cancel-button {
    background-color: $orange-color;
    border: 1px solid $orange-color;
    border-radius: 8px;
    height: 35px;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    text-decoration: none;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
  }
}

.stock-table {
  overflow-x: auto;
}
