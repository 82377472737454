$white-color: #ffffff;
$background-color: #f7f8f7;
$green-color: #26c44b;
$green-color-2: #14762b;
$red-color: #e02760;
$black-color: #252e3c;
$black-color-2: #222e3d;
$gray-color: #555555;
$gray-color-2: #808a95;
$gray-color-3: #d3d3d3;
$gray-color-4: #535b66;
$gray-color-5: #a0aec1;
$gray-color-6: #f8f9fa;
$gray-color-7: #707070;
$gray-color-8: #f2f2f2;
$gray-color-9: #cccccc;
$orange-color: #e0ab27;
$orange-color-2: #e09301;
$orange-color-3: #e09200;
$blue-color: #278ae0;
$blue-color-2: #0092fd;
$pink-color: #e027ca;
