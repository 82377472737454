@mixin ellipsis-text($size) {
  .ellipsis-text-#{$size} {
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: #{$size}px;
  }
}

@mixin ellipsis-text-multiline($lines) {
  .ellipsis-text-multiline-#{$lines} {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
  }
}
