@import 'colors';
@import 'variables';
@import 'mixins';

@media (max-width: 1080px) {
  .pos-details {
    .pos-products-table {
      .first-column-name {
        max-width: 48px;
      }
    }
  }

  .aside {
    position: absolute;
    height: calc(100% - 46px);
    margin-top: 46px;

    &.collapsed {
      display: none !important;
    }
  }

  .products-view {
    .product-name {
      max-width: 250px;
      white-space: normal;
    }
  }

  @include ellipsis-text(100);
  @include ellipsis-text(150);
  @include ellipsis-text(200);
  @include ellipsis-text-multiline(2);
  @include ellipsis-text-multiline(3);
}

@media (max-width: 1024px) {
  .create-button {
    height: 35px;
    max-width: 120px;
  }

  .container-product-inventory {
    .stock-table {
      table {
        tbody {
          tr {
            td:first-child {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 100px;
            }
          }
        }
      }
    }
  }

  .stores {
    table {
      tbody {
        tr {
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(4) {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100px;
          }
        }
      }
    }
  }

  .notificaciones {
    table {
      tbody {
        tr {
          td:last-child {
            width: 100%;
            white-space: normal;
          }
        }
      }
    }
  }

  .pos-products-table-container {
    .pos-products-table {
      .name {
        font-size: 12px;
      }

      .sale-checkbox {
        position: absolute;
        left: -20px;
      }

      .mobile-product-thumbnail {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .logout-modal {
    width: 90vw;
  }

  .hide-mobile {
    display: block;
  }

  .hide-desktop {
    display: none;
  }

  .login {
    .home-logo {
      max-width: 290px;
    }

    form {
      width: 330px;
    }
  }

  .header {
    height: 64px;

    .container-header {
      .logo {
        margin-right: 10px;

        img {
          width: 50px;
        }
      }

      .company-selector {
        margin-left: 0;

        select {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 120px;
        }
      }
    }
  }

  .aside {
    position: absolute;
    height: calc(100% - 64px);
    margin-top: 64px;
  }

  .dashboard {
    ul.dashboard-tabs {
      li {
        flex-grow: 1;

        a {
          padding: 8px;
          width: 100%;
        }
      }
    }
  }

  .sales {
    .__header {
      .dropdown {
        margin-top: 1rem;
        flex-grow: 1;
      }
    }
  }
}
